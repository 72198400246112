import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
    Badge,
    Box,
    Divider,
    Flex,
    Icon,
    Spinner,
    Table,
    Td,
    Text,
    Th,
    Tooltip,
    Tr,
} from '@chakra-ui/react';

import { FaCheckCircle } from 'react-icons/fa';
import { RiCloseCircleLine } from 'react-icons/ri';
import { Image, Transformation } from 'cloudinary-react';

const FETCH = gql`
    query FetchCourse($filter: FilterFindOneUserCourseInput!) {
        userCourseGet(filter: $filter) {
            _id
            user {
                first_name
                last_name
                email
            }
            overall_progress
            modules {
                title
                module_progress
                module_status
                quiz {
                    is_multiple
                    question
                    options {
                        answer
                        image_answer
                        isCorrect
                        _id
                    }
                }
                quiz_submissions {
                    score
                    status
                    submitted
                    questions {
                        options {
                            answer
                            isCorrect
                            image_answer
                            isSelected
                        }
                    }
                }
            }
        }
    }
`;

const EditForm = () => {
    const { id } = useParams();
    const { data } = useQuery(FETCH, { variables: { filter: { _id: id } } });

    const course = data?.userCourseGet;

    const handleMultiAnswers = (options) => {
        const numberOfCorrect = options.filter((e) => e.isCorrect);
        const userHasCorrect = options.filter(
            (f) => f.isCorrect === true && f.isSelected === true
        );

        return numberOfCorrect.length === userHasCorrect.length ? true : false;
    };

    return course ? (
        <Flex w='100%' h='100%' bg='gray.50' direction='column'>
            <Flex p='20px' direction='column'>
                <Text fontWeight='600' fontSize='1.2em'>
                    Viewing Course for {course.user.first_name}{' '}
                    {course.user.last_name}
                </Text>
                <Text>{course.user.email}</Text>
            </Flex>

            <Flex direction='column' w='100%' px='20px'>
                {course.modules.map((module, i) => (
                    <Flex
                        key={`module-${i}`}
                        w='100%'
                        mb='20px'
                        rounded='5px'
                        bg='white'
                        p='15px'
                        direction='column'
                    >
                        <Text fontWeight='bold' mb='20px'>
                            {module.title}
                            <Badge
                                ml='20px'
                                variant='solid'
                                colorScheme={
                                    module.module_status === 'PASS'
                                        ? 'green'
                                        : 'red'
                                }
                            >
                                Module {module.module_status}
                            </Badge>
                        </Text>

                        {module.quiz_submissions &&
                        module.quiz_submissions.length !== 0 ? (
                            <Table variant='striped' size='sm'>
                                <Tr>
                                    <Th>Question</Th>
                                    <Th>Attempt 1</Th>
                                    <Th>Attempt 2</Th>
                                    <Th>Attempt 3</Th>
                                    <Th>Attempt 4</Th>
                                </Tr>
                                {module.quiz.map((quiz, ix) => (
                                    <Tr key={`quiz-row-${ix}`}>
                                        <Td>
                                            <Text fontWeight='bold'>
                                                {quiz.question}
                                            </Text>
                                            <br />
                                            <Text
                                                mb='0.5em'
                                                textDecoration='underline'
                                            >
                                                Correct Answer(s):{' '}
                                            </Text>
                                            {quiz.options
                                                .filter((e) => e.isCorrect)
                                                .map((ca, index) => (
                                                    <>
                                                        <Flex w='100%'>
                                                            <Badge
                                                                colorScheme='blue'
                                                                minWidth={'2em'}
                                                            ></Badge>
                                                            {ca.image_answer ? (
                                                                <Image
                                                                    publicId={
                                                                        ca
                                                                            .image_answer[0]
                                                                            .public_id
                                                                    }
                                                                    secure='true'
                                                                >
                                                                    <Transformation
                                                                        width='100'
                                                                        crop='thumb'
                                                                        fetchFormat='auto'
                                                                    />
                                                                </Image>
                                                            ) : (
                                                                <Text mx='1em'>
                                                                    {ca.answer}
                                                                </Text>
                                                            )}
                                                        </Flex>
                                                        <Divider />
                                                    </>
                                                ))}
                                        </Td>
                                        {module.quiz_submissions.map(
                                            (sub, sx) => (
                                                <Td key={`ms-${sub._id}-${i}`}>
                                                    {quiz.is_multiple ? (
                                                        handleMultiAnswers(
                                                            module
                                                                .quiz_submissions[
                                                                sx
                                                            ].questions[ix]
                                                                .options
                                                        ) ? (
                                                            <Icon
                                                                fontSize='20px'
                                                                color='green'
                                                            >
                                                                <FaCheckCircle />
                                                            </Icon>
                                                        ) : (
                                                            <>
                                                                <Tooltip
                                                                    label={module.quiz_submissions[
                                                                        sx
                                                                    ]?.questions[
                                                                        ix
                                                                    ].options
                                                                        .filter(
                                                                            (
                                                                                e
                                                                            ) =>
                                                                                e.isSelected
                                                                        )
                                                                        .map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    <Flex w='100%'>
                                                                                        <Badge
                                                                                            colorScheme='blue'
                                                                                            minWidth={
                                                                                                '2em'
                                                                                            }
                                                                                        ></Badge>

                                                                                        {item.image_answer ? (
                                                                                            <Image
                                                                                                publicId={
                                                                                                    item
                                                                                                        .image_answer[0]
                                                                                                        .public_id
                                                                                                }
                                                                                                secure='true'
                                                                                            >
                                                                                                <Transformation
                                                                                                    width='100'
                                                                                                    crop='thumb'
                                                                                                    fetchFormat='auto'
                                                                                                />
                                                                                            </Image>
                                                                                        ) : (
                                                                                            <Text mx='1em'>
                                                                                                {
                                                                                                    item.answer
                                                                                                }
                                                                                            </Text>
                                                                                        )}
                                                                                    </Flex>
                                                                                    <Divider />
                                                                                </>
                                                                            )
                                                                        )}
                                                                >
                                                                    <Box>
                                                                        <Icon
                                                                            fontSize='20px'
                                                                            color='red'
                                                                        >
                                                                            <RiCloseCircleLine />
                                                                        </Icon>{' '}
                                                                        <Text
                                                                            fontSize='10px'
                                                                            opacity={
                                                                                0.7
                                                                            }
                                                                        >
                                                                            (Hover
                                                                            for
                                                                            selected)
                                                                        </Text>
                                                                    </Box>
                                                                </Tooltip>
                                                            </>
                                                        )
                                                    ) : module.quiz_submissions[
                                                          sx
                                                      ] ? (
                                                        module.quiz_submissions[
                                                            sx
                                                        ].questions[
                                                            ix
                                                        ].options.some(
                                                            (e) =>
                                                                e.isCorrect &&
                                                                e.isSelected
                                                        ) ? (
                                                            <Icon
                                                                fontSize='20px'
                                                                color='green'
                                                            >
                                                                <FaCheckCircle />
                                                            </Icon>
                                                        ) : (
                                                            <>
                                                                <Tooltip
                                                                    label={
                                                                        module.quiz_submissions[
                                                                            sx
                                                                        ]?.questions[
                                                                            ix
                                                                        ].options.filter(
                                                                            (
                                                                                e
                                                                            ) =>
                                                                                e.isSelected
                                                                        )[0]
                                                                            .answer
                                                                    }
                                                                >
                                                                    <Box>
                                                                        <Icon
                                                                            fontSize='20px'
                                                                            color='red'
                                                                        >
                                                                            <RiCloseCircleLine />
                                                                        </Icon>{' '}
                                                                        <Text
                                                                            fontSize='10px'
                                                                            opacity={
                                                                                0.7
                                                                            }
                                                                        >
                                                                            (Hover
                                                                            for
                                                                            selected)
                                                                        </Text>
                                                                    </Box>
                                                                </Tooltip>
                                                            </>
                                                        )
                                                    ) : null}
                                                </Td>
                                            )
                                        )}
                                        {/* <Td>
                                            {quiz.is_multiple ? (
                                                handleMultiAnswers(
                                                    module.quiz_submissions[0]
                                                        .questions[ix].options
                                                ) ? (
                                                    <Icon
                                                        fontSize='20px'
                                                        color='green'
                                                    >
                                                        <FaCheckCircle />
                                                    </Icon>
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={module.quiz_submissions[0]?.questions[
                                                                ix
                                                            ].options
                                                                .filter(
                                                                    (e) =>
                                                                        e.isSelected
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <>
                                                                            <Flex w='100%'>
                                                                                <Badge
                                                                                    colorScheme='blue'
                                                                                    minWidth={
                                                                                        '2em'
                                                                                    }
                                                                                ></Badge>

                                                                                {item.image_answer ? (
                                                                                    <Image
                                                                                        publicId={
                                                                                            item
                                                                                                .image_answer[0]
                                                                                                .public_id
                                                                                        }
                                                                                        secure='true'
                                                                                    >
                                                                                        <Transformation
                                                                                            width='100'
                                                                                            crop='thumb'
                                                                                            fetchFormat='auto'
                                                                                        />
                                                                                    </Image>
                                                                                ) : (
                                                                                    <Text mx='1em'>
                                                                                        {
                                                                                            item.answer
                                                                                        }
                                                                                    </Text>
                                                                                )}
                                                                            </Flex>
                                                                            <Divider />
                                                                        </>
                                                                    )
                                                                )}
                                                        >
                                                            <Box>
                                                                <Icon
                                                                    fontSize='20px'
                                                                    color='red'
                                                                >
                                                                    <RiCloseCircleLine />
                                                                </Icon>{' '}
                                                                <Text
                                                                    fontSize='10px'
                                                                    opacity={
                                                                        0.7
                                                                    }
                                                                >
                                                                    (Hover for
                                                                    selected)
                                                                </Text>
                                                            </Box>
                                                        </Tooltip>
                                                    </>
                                                )
                                            ) : module.quiz_submissions[0] ? (
                                                module.quiz_submissions[0].questions[
                                                    ix
                                                ].options.some(
                                                    (e) =>
                                                        e.isCorrect &&
                                                        e.isSelected
                                                ) ? (
                                                    <Icon
                                                        fontSize='20px'
                                                        color='green'
                                                    >
                                                        <FaCheckCircle />
                                                    </Icon>
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={
                                                                module.quiz_submissions[0]?.questions[
                                                                    ix
                                                                ].options.filter(
                                                                    (e) =>
                                                                        e.isSelected
                                                                )[0].answer
                                                            }
                                                        >
                                                            <Box>
                                                                <Icon
                                                                    fontSize='20px'
                                                                    color='red'
                                                                >
                                                                    <RiCloseCircleLine />
                                                                </Icon>{' '}
                                                                <Text
                                                                    fontSize='10px'
                                                                    opacity={
                                                                        0.7
                                                                    }
                                                                >
                                                                    (Hover for
                                                                    selected)
                                                                </Text>
                                                            </Box>
                                                        </Tooltip>
                                                    </>
                                                )
                                            ) : null}
                                        </Td> */}
                                        {/* <Td>
                                            {quiz.is_multiple &&
                                            module.quiz_submissions[1]
                                                ?.questions ? (
                                                handleMultiAnswers(
                                                    module.quiz_submissions[1]
                                                        ?.questions?.[ix]
                                                        .options
                                                ) ? (
                                                    <Icon
                                                        fontSize='20px'
                                                        color='green'
                                                    >
                                                        <FaCheckCircle />
                                                    </Icon>
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={module.quiz_submissions[1]?.questions[
                                                                ix
                                                            ].options
                                                                .filter(
                                                                    (e) =>
                                                                        e.isSelected
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <>
                                                                            <Flex>
                                                                                <Badge
                                                                                    colorScheme='blue'
                                                                                    minWidth={
                                                                                        '2em'
                                                                                    }
                                                                                ></Badge>
                                                                                {item.image_answer ? (
                                                                                    <Image
                                                                                        publicId={
                                                                                            item
                                                                                                .image_answer[0]
                                                                                                .public_id
                                                                                        }
                                                                                        secure='true'
                                                                                    >
                                                                                        <Transformation
                                                                                            width='100'
                                                                                            crop='thumb'
                                                                                            fetchFormat='auto'
                                                                                        />
                                                                                    </Image>
                                                                                ) : (
                                                                                    <Text mx='1em'>
                                                                                        {
                                                                                            item.answer
                                                                                        }
                                                                                    </Text>
                                                                                )}
                                                                            </Flex>
                                                                            <Divider />
                                                                        </>
                                                                    )
                                                                )}
                                                        >
                                                            <Box>
                                                                <Icon
                                                                    fontSize='20px'
                                                                    color='red'
                                                                >
                                                                    <RiCloseCircleLine />
                                                                </Icon>{' '}
                                                                <Text
                                                                    fontSize='10px'
                                                                    opacity={
                                                                        0.7
                                                                    }
                                                                >
                                                                    (Hover for
                                                                    selected)
                                                                </Text>
                                                            </Box>
                                                        </Tooltip>
                                                    </>
                                                )
                                            ) : module.quiz_submissions[1] ? (
                                                module.quiz_submissions[1].questions[
                                                    ix
                                                ].options.some(
                                                    (e) =>
                                                        e.isCorrect &&
                                                        e.isSelected
                                                ) ? (
                                                    <Icon
                                                        fontSize='20px'
                                                        color='green'
                                                    >
                                                        <FaCheckCircle />
                                                    </Icon>
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={
                                                                module.quiz_submissions[1]?.questions[
                                                                    ix
                                                                ].options.filter(
                                                                    (e) =>
                                                                        e.isSelected
                                                                )[0].answer
                                                            }
                                                        >
                                                            <Box>
                                                                <Icon
                                                                    fontSize='20px'
                                                                    color='red'
                                                                >
                                                                    <RiCloseCircleLine />
                                                                </Icon>{' '}
                                                                <Text
                                                                    fontSize='10px'
                                                                    opacity={
                                                                        0.7
                                                                    }
                                                                >
                                                                    (Hover for
                                                                    selected)
                                                                </Text>
                                                            </Box>
                                                        </Tooltip>
                                                    </>
                                                )
                                            ) : null}
                                        </Td> */}
                                        {/* <Td>
                      {module.quiz_submissions[1] ? (
                        module.quiz_submissions[1]?.questions[ix].options.some(
                          (e) => e.isCorrect && e.isSelected
                        ) ? (
                          <>
                            <Icon fontSize='20px' color='green'>
                              <FaCheckCircle />
                            </Icon>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              label={
                                module.quiz_submissions[1]?.questions[
                                  ix
                                ].options.filter((e) => e.isSelected)[0].answer
                              }
                            >
                              <Box>
                                <Icon fontSize='20px' color='red'>
                                  <RiCloseCircleLine />
                                </Icon>{' '}
                                (Hover)
                              </Box>
                            </Tooltip>
                          </>
                        )
                      ) : null}
                    </Td> */}
                                    </Tr>
                                ))}
                                <Tr>
                                    <Td></Td>
                                    {module.quiz_submissions.map((sub, sx) => (
                                        <Td>
                                            <Badge
                                                mr='10px'
                                                colorScheme={
                                                    module.quiz_submissions[sx]
                                                        .status === 'PASS'
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            >
                                                {
                                                    module.quiz_submissions[sx]
                                                        .status
                                                }
                                            </Badge>
                                            {module.quiz_submissions[sx]
                                                ? `${module.quiz_submissions[sx].score}%`
                                                : 'N/A'}
                                        </Td>
                                    ))}
                                </Tr>
                            </Table>
                        ) : (
                            <Text>This module is in progress</Text>
                        )}
                    </Flex>
                ))}
            </Flex>
        </Flex>
    ) : (
        <Flex w='100%' h='100%' align='center' justify='center' p='50px'>
            <Spinner />
        </Flex>
    );
};

export default EditForm;
