import React from 'react';

import { LinkButton, ListWrapper } from '../../Nucleus';
import { Box, Text, Button, Badge } from '@chakra-ui/react';
import setup from './setup';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';

const FETCHDATA = gql`
    query ListExamApplications(
        $filter: FilterFindManyExamApplicationInput
        $sort: SortFindManyExamApplicationInput
        $page: Int
        $perPage: Int
    ) {
        examApplicationList(
            filter: $filter
            sort: $sort
            page: $page
            perPage: $perPage
        ) {
            items {
                _id
                course {
                    course {
                        title
                    }
                    finished_date
                    overall_progress
                    course_status
                }
                email
                first_name
                last_name
                status
                failed_date
                passed_date
                scheduled_date
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

const DELETE = gql`
    mutation ($input: [String]) {
        courseDelete(input: $input) {
            total
        }
    }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'User',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row }) => (
                <Text>
                    {row.original.first_name} {row.original.last_name}
                </Text>
            ),
        },
        {
            Header: 'Status',
            accessor: 'scheduled_date',
            disableSortBy: true,
            Cell: ({ value }) =>
                value ? (
                    <Badge colorScheme='green'>Scheduled</Badge>
                ) : (
                    <Badge colorScheme='red'>Awaiting Exam Date</Badge>
                ),
        },
        {
            Header: 'Scheduled Date',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row }) =>
                row.original.scheduled_date ? (
                    <Text>
                        {dayjs(row.original.scheduled_date).format(
                            'DD/MM/YYYY'
                        )}
                    </Text>
                ) : null,
        },
        {
            Header: 'Exam Attempt',
            accessor: 'course.course_status',
            disableSortBy: true,
            Cell: ({ value }) =>
                value === 'FIRST_EXAM_FAILED' ? (
                    <Badge colorScheme='orange'>Second</Badge>
                ) : (
                    <Badge colorScheme='green'>First</Badge>
                ),
        },

        {
            Header: 'Course Progress',
            accessor: 'course.overall_progress',
            disableSortBy: true,
            Cell: ({ value }) => (value ? `${value}%` : '0%'),
        },

        {
            Header: '',
            accessor: 'course',

            disableSortBy: true,
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button size='sm' colorScheme='blue'>
                        Edit Examination
                    </Button>
                </LinkButton>
            ),
        },
    ];

    const filters = [
        {
            value: 'status',
            type: 'select',
            label: 'Exam Status',
            selectProps: {
                items: [
                    {
                        value: 'PASS',
                        label: 'Passed',
                    },
                    {
                        value: 'FAIL',
                        label: 'Failed',
                    },
                ],
                selectValue: 'value',
                isMulti: false,
            },
        },

        {
            value: 'scheduled_date',
            type: 'operator',
            isOperator: true,
            operators: [
                // {
                //     label: 'Includes',
                //     value: 'in',
                // },
                // {
                //     label: 'Excludes',
                //     value: 'nin',
                // },
                {
                    label: 'Greater than',
                    value: 'gt',
                },
                {
                    label: 'Less than',
                    value: 'lt',
                },
            ],
            label: 'Scheduled Date',
            selectorType: 'date',
        },
    ];

    return (
        <Box>
            <ListWrapper
                setup={setup}
                columns={columns}
                extraMenu={extraMenu}
                gqlFetch={FETCHDATA}
                gqlDelete={DELETE}
                defaultFilter={{
                    _operators: { status: { nin: ['PASS', 'FAIL'] } },
                }}
                defaultSort={{}}
                filters={filters}
                showTopPagination={false}
            />
        </Box>
    );
};

export default List;
