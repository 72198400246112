import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, ButtonGroup, Flex, Stack, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authAtom } from '../../state/auth';
import TableComponent from '../Table';
import { tableAtom, tableFilterAtom } from './atoms';
import OperatorSearchSelect from './OperatorSearchSelect';
import SearchInput from './SearchInput';
import TableActions from './TableActions';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import TableSort from './TableSort';

const ListView = ({
    setup,
    columns,
    handleRowSelection,
    gqlDelete,
    gqlFetch,
    filters,
    defaultFilter,
    renderRowSubComponent,
    extraMenu,
    searchFunction,
    otherData: externalData,
}) => {
    const ref = useRef(null);
    const { currentUser } = useRecoilValue(authAtom);
    const [selected, setSelected] = useState([]);
    const [tableState, setTableState] = useRecoilState(tableAtom);
    const [dataFilters, setFilters] = useRecoilState(tableFilterAtom);
    const ExtraComponent = extraMenu;

    const [otherData, setOtherData] = useState(null);

    useEffect(() => {
        if (externalData && Object.keys(externalData).length !== 0) {
            setOtherData(externalData);
        }
    }, [externalData]);

    /// DATA FETCHING

    const [fetchData, { loading, error, data }] = useLazyQuery(gqlFetch);

    useEffect(() => {
        fetchData({
            variables: {
                filter: dataFilters,
                sort: tableState.params.sort,
                page: tableState.params.page,
                perPage: tableState.params.pagination,
            },
            fetchPolicy: 'network-only',
        });
    }, [currentUser, dataFilters, tableState]);

    useEffect(() => {
        if (loading && !data) {
            ref.current.continuousStart();
        }
        if ((!loading && data) || error) {
            ref.current.complete();
        }
    }, [loading]);

    /// ROW SELECTION

    const handleRowSelect = useCallback((rows) => {
        const flat = rows ? rows.map((d) => d.original[setup.accessor]) : [];
        setSelected((old) => {
            if (old != flat) {
                setSelected(flat);
            }
        });
        setTableState((old) => ({
            ...old,
            selected: flat,
            selectedRows:
                rows && rows.length !== 0 ? rows.map((d) => d.original) : [],
        }));
        if (handleRowSelection) {
            handleRowSelection(rows);
        }
    }, []);

    /// DELETE

    const [deleteItems, { data: deleteResponse, loading: deleteLoading }] =
        useMutation(gqlDelete);

    const onDelete = async () => {
        await deleteItems({ variables: { input: tableState.selected } });
        window.location.reload();
    };

    /// SEARCHING

    const changeSearch = (value) => {
        if (searchFunction) {
            if (value.length === 0 || !value) {
                setFilters((old) => ({ ...old, search: value }));
            } else {
                searchFunction(value);
            }
        } else {
            setFilters((old) => ({ ...old, search: value }));
        }
    };

    useEffect(() => {
        if (defaultFilter) {
            setFilters(defaultFilter);
        }
    }, [defaultFilter]);

    const handleChangePage = (page) => {
        setTableState((old) => ({
            ...old,
            params: {
                ...tableState.params,
                page: page,
            },
        }));
    };

    return (
        <Flex w='100%' direction='column' minHeight='500px'>
            <Box pos='fixed' zIndex={9999} w='100%' left={0} top={0}>
                <LoadingBar color='#366DDD' ref={ref} />
            </Box>
            <Flex
                w='100%'
                h='60px'
                borderBottom='1px'
                borderColor='blackAlpha.300'
                align='center'
                position='relative'
                zIndex={1000}
                px={5}
            >
                <Text as='h1' fontWeight='semibold' fontSize='18px'>
                    {setup.title}
                </Text>

                <Flex ml='auto' align='center'>
                    {extraMenu && (
                        <Box mr='20px'>
                            <ExtraComponent />
                        </Box>
                    )}

                    {setup.canAddNew === true && (
                        <Box>
                            <Link to={`${setup.model}/create`}>
                                <Button variant='primary' leftIcon={<FiPlus />}>
                                    Create New
                                </Button>
                            </Link>
                        </Box>
                    )}
                </Flex>
            </Flex>
            <Flex align='center' p='10px' zIndex={100} pos='relative'>
                <Stack isInline spacing='10px'>
                    {setup.canSearch && (
                        <SearchInput
                            changeSearch={changeSearch}
                            setOtherData={setOtherData}
                        />
                    )}

                    <ButtonGroup
                        size='md'
                        isAttached
                        variant='outline'
                        colorScheme='gray'
                    >
                        <OperatorSearchSelect
                            filters={filters}
                            defaultFilter={defaultFilter}
                        />
                        <TableSort columns={columns} />
                        <TableActions
                            columns={columns}
                            setup={setup}
                            onDelete={onDelete}
                            selected={selected}
                        />
                    </ButtonGroup>
                </Stack>
            </Flex>
            <TableComponent
                data={
                    otherData
                        ? { items: Object.values(otherData)[0] }
                        : data
                        ? Object.values(data)[0]
                        : { items: [], pageInfo: {} }
                }
                columns={columns}
                onRowSelect={
                    setup.canSelect === undefined
                        ? handleRowSelect
                        : setup.canSelect
                        ? handleRowSelect
                        : null
                }
                onChangePage={handleChangePage}
                setup={setup}
                accessor={setup.accessor}
                loading={loading}
                error={error}
                renderRowSubComponent={renderRowSubComponent}
            />
        </Flex>
    );
};

export default ListView;
