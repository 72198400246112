import React from 'react';
import { Link } from 'react-router-dom';
import { ListWrapper } from '../../Nucleus';
import { Box, Text, Badge, Button } from '@chakra-ui/react';
import setup from './setup';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';

const FETCHDATA = gql`
    query ListUserCourses(
        $filter: FilterFindManyUserCourseInput
        $sort: SortFindManyUserCourseInput
        $page: Int
        $perPage: Int
    ) {
        userCourseList(
            filter: $filter
            sort: $sort
            page: $page
            perPage: $perPage
        ) {
            items {
                _id
                course {
                    title
                }
                user {
                    _id
                    email
                    first_name
                    last_name
                }
                start_date
                limit_date
                finished_date
                completed_date
                course_status
                failed_date
                overall_progress
                createdAt
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

const DELETE = gql`
    mutation ($input: [String]) {
        deleteCourses(input: $input) {
            total
        }
    }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'User',
            accessor: 'user',
            disableSortBy: true,
            Cell: ({ row }) =>
                row.original.user ? (
                    <Link
                        to={`${setup.model}/edit/${
                            row.original[setup.accessor]
                        }`}
                    >
                        <Button
                            as='a'
                            variant='link'
                            colorScheme='blue'
                            size='sm'
                        >
                            {row.original.user.first_name}{' '}
                            {row.original.user.last_name} -{' '}
                            {row.original.user.email}
                        </Button>
                    </Link>
                ) : (
                    `User  deleted - ${row.original._id}`
                ),
        },
        {
            Header: 'Progress',
            accessor: 'overall_progress',
            disableSortBy: false,
            Cell: ({ row, value }) =>
                value ? `${parseInt(value).toFixed(0)}%` : 0,
        },
        {
            Header: 'Purchase Date',
            accessor: 'createdAt',
            disableSortBy: false,
            Cell: ({ value }) =>
                value
                    ? `${dayjs(value).format('DD/MM/YYYY')} (${dayjs().diff(
                          value,
                          'day'
                      )} days ago)`
                    : 'None',
        },
        {
            Header: 'Start Date',
            accessor: 'start_date',
            disableSortBy: false,
            Cell: ({ value }) =>
                value ? dayjs(value).format('DD/MM/YYYY') : 'Not Started',
        },
        {
            Header: 'End Date',
            accessor: 'limit_date',
            disableSortBy: false,
            Cell: ({ row, value }) =>
                row.original.start_date
                    ? dayjs(value).format('DD/MM/YYYY')
                    : 'Not Started',
        },
        {
            Header: 'Status',
            accessor: 'course_status',
            disableSortBy: true,
            Cell: ({ value }) => {
                if (
                    value === 'FIRST_EXAM_PASSED' ||
                    value === 'SECOND_EXAM_PASSED'
                ) {
                    return <Badge colorScheme='green'>{value}</Badge>;
                }
                if (value === 'NOT_STARTED' || value === 'IN_PROGRESS') {
                    return <Badge>{value}</Badge>;
                }
                if (
                    value === 'FIRST_EXAM_FAILED' ||
                    value === 'SECOND_EXAM_FAILED' ||
                    value === 'FAIL'
                ) {
                    return <Badge colorScheme='red'>{value}</Badge>;
                }
                return <Badge>{value}</Badge>;
            },
        },
    ];

    const filters = [
        {
            value: 'course_status',
            type: 'select',
            label: 'Course Status',
            selectProps: {
                items: [
                    {
                        value: 'EXAM_SCHEDULED',
                        label: 'Upcoming / Scheduled',
                    },
                    {
                        value: 'PASS',
                        label: 'Passed',
                    },
                    {
                        value: 'FAIL',
                        label: 'Failed',
                    },
                    {
                        value: 'FIRST_EXAM_PASSED',
                        label: 'First Exam Passed',
                    },
                    {
                        value: 'FIRST_EXAM_FAILED',
                        label: 'First Exam Failed',
                    },
                    {
                        value: 'SECOND_EXAM_PASSED',
                        label: 'Second Exam Passed',
                    },
                    {
                        value: 'SECOND_EXAM_FAILED',
                        label: 'Second Exam Failed',
                    },
                ],
                selectValue: 'value',
                isMulti: false,
            },
        },
    ];

    const SEARCH = gql`
        query SearchUserCourses($search: String) {
            searchUserCourses(search: $search) {
                _id
                course {
                    title
                }
                user {
                    _id
                    email
                    first_name
                    last_name
                }
                start_date
                limit_date
                finished_date
                completed_date
                course_status
                failed_date
                overall_progress
                createdAt
            }
        }
    `;

    const [handleSearchQuery, { data: searchData }] = useLazyQuery(SEARCH);

    const handleSearch = (value) => {
        handleSearchQuery({
            variables: {
                search: value,
            },
        });
    };

    return (
        <Box>
            <ListWrapper
                setup={setup}
                columns={columns}
                extraMenu={extraMenu}
                gqlFetch={FETCHDATA}
                gqlDelete={DELETE}
                defaultFilter={null}
                defaultSort={'CREATEDAT_ASC'}
                filters={filters}
                showTopPagination={false}
                searchFunction={handleSearch}
                otherData={searchData}
            />
        </Box>
    );
};

export default List;
