import React from 'react';
import { useEffect, memo, useState, Fragment } from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useResizeColumns,
    useRowSelect,
    useExpanded,
} from 'react-table';

import {
    Box,
    Flex,
    Button,
    Checkbox,
    IconButton,
    useColorModeValue,
    Table,
    Thead,
    Tr,
    Td,
    Th,
    Container,
    Tbody,
} from '@chakra-ui/react';

import { BsArrowsExpand, BsArrowsCollapse } from 'react-icons/bs';

import { HiChevronDown, HiChevronRight } from 'react-icons/hi';

import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import Loading from './Loading';
import Error from './Error';

const HeaderColumn = memo(
    ({ column, isSorted, isSortedDesc, disableSortBy }) => {
        const color = useColorModeValue('gray.800', 'gray.100');
        return (
            <Th
                {...column.getHeaderProps()}
                _hover={{ cursor: disableSortBy ? 'default' : 'default ' }}
                style={{ position: 'sticky', zIndex: 10 }}
                textTransform='none'
                letterSpacing='normal'
                color={color}
                borderColor='gray.200'
                fontWeight='500'
                fontSize='1rem'
                h='35px'
                zIndex={2}
            >
                <Flex
                    align='center'
                    justify='flex-start'
                    w='100%'
                    textAlign='left'
                    {...column.getSortByToggleProps()}
                >
                    {column.render('Header')}

                    <Box ml='auto'>
                        {isSorted ? (
                            isSortedDesc ? (
                                <FaSortAmountDownAlt />
                            ) : (
                                <FaSortAmountDown />
                            )
                        ) : (
                            ''
                        )}
                    </Box>
                </Flex>
            </Th>
        );
    }
);

const TableCell = memo(({ cell }) => {
    return (
        <Td
            {...cell.getCellProps()}
            as='td'
            borderColor='gray.200'
            whiteSpace='nowrap'
        >
            {cell.render('Cell')}
        </Td>
    );
});

const TableRow = ({
    row,
    size,
    onRowSelect,
    isExpandable,
    toggleRowExpanded,
    renderRowSubComponent,
    visibleColumns,
}) => {
    return (
        <React.Fragment {...row.getRowProps()}>
            <Tr as='tr'>
                {isExpandable && (
                    <Td
                        textOverflow='normal'
                        whiteSpace='nowrap'
                        px={!isExpandable && '0px'}
                        style={{ minWidth: 45, width: 45 }}
                    >
                        <IconButton
                            rounded='md'
                            variant='link'
                            fontSize='1.4em'
                            onClick={() => toggleRowExpanded(row.id)}
                        >
                            {row.isExpanded ? (
                                <HiChevronDown />
                            ) : (
                                <HiChevronRight />
                            )}
                        </IconButton>
                    </Td>
                )}

                <Td
                    style={{ minWidth: 45, width: 45 }}
                    borderColor='gray.200'
                    pos='sticky'
                    left='0px'
                    bg='white'
                >
                    <IndeterminateCheckbox
                        onRowSelect={onRowSelect}
                        {...row.getToggleRowSelectedProps()}
                    />
                </Td>

                {row.cells.map((cell, i) => {
                    return (
                        <TableCell key={`cell-${i}`} cell={cell} size={size} />
                    );
                })}
            </Tr>
            {row.isExpanded && (
                <Tr>
                    <Td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                    </Td>
                </Tr>
            )}
        </React.Fragment>
    );
};

const IndeterminateCheckbox = memo(
    ({ indeterminate, checked, onRowSelect, ...rest }) => {
        return (
            <Flex
                align='center'
                justify='center'
                w='100%'
                h='100%'
                opacity={onRowSelect === null && 0.7}
                style={{ minWidth: 5 }}
            >
                <Checkbox
                    isIndeterminate={indeterminate}
                    isChecked={checked}
                    size='lg'
                    isDisabled={onRowSelect === null && true}
                    {...rest}
                />
            </Flex>
        );
    }
);

const TableComponent = (props) => {
    const {
        columns = [],
        data,
        showHeader,
        loading,
        onChangePageSize,
        onChangePage,
        onRowSelect = null,
        onSortSelect,
        manualPagination = true,
        hidePagination = false,
        accessor,
        error,
        isExpandable,
        disableSortBy,
        size,
        renderRowSubComponent,
    } = props;

    const {
        getTableProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        selectedFlatRows,
        nextPage,
        previousPage,
        setPageSize,
        getToggleRowSelectedProps,
        getToggleAllRowsSelectedProps,
        canPreviousPage,
        canNextPage,
        isAllRowsExpanded,
        toggleAllRowsExpanded,
        toggleRowExpanded,
        visibleColumns,
        state: { pageSize, sortBy },
    } = useTable(
        {
            columns,
            manualRowSelectedKey: 'isSelected',
            manualExpandedKey: 'isExpanded',
            data: data ? data.items : [],
            manualSortBy: true,
            disableSortBy: disableSortBy,
            manualPagination: manualPagination,
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useResizeColumns
    );

    useEffect(() => {
        if (onRowSelect !== null && selectedFlatRows.length !== 0) {
            onRowSelect(selectedFlatRows);
        }
    }, [selectedFlatRows]);

    useEffect(() => {
        if (onSortSelect) {
            onSortSelect(sortBy);
        }
    }, [sortBy]);

    const rowMap = manualPagination ? rows : page;

    // Render the UI for your table
    return (
        <Box w='100%' overflow='hidden' pos='relative'>
            <Error open={error ? error : false} />
            <Loading loading={loading} />

            <Box w='100%' overflowX='auto' pt='10px'>
                <Table
                    {...getTableProps()}
                    size={size}
                    mb='20px'
                    minWidth='100%'
                    w='100%'
                >
                    <Thead mb='5px'>
                        {showHeader &&
                            headerGroups?.map((headerGroup, i) => (
                                <Tr
                                    key={`header-${i}`}
                                    {...headerGroup.getHeaderGroupProps()}
                                    as='tr'
                                >
                                    {isExpandable && (
                                        <Th
                                            position='sticky'
                                            top='0px'
                                            zIndex={10}
                                            px={!isExpandable && '0px'}
                                            style={{ minWidth: 45, width: 45 }}
                                            as='td'
                                            bg='white'
                                        >
                                            <IconButton
                                                variant='link'
                                                fontSize='1.2em'
                                                onClick={() =>
                                                    toggleAllRowsExpanded()
                                                }
                                            >
                                                {isAllRowsExpanded ? (
                                                    <BsArrowsCollapse />
                                                ) : (
                                                    <BsArrowsExpand />
                                                )}
                                            </IconButton>
                                        </Th>
                                    )}

                                    <Th
                                        position='sticky'
                                        top='0px'
                                        left='0px'
                                        zIndex={15}
                                        style={{ minWidth: 45, width: 45 }}
                                        as='td'
                                        bg='white'
                                    >
                                        <IndeterminateCheckbox
                                            onRowSelect={onRowSelect}
                                            {...getToggleAllRowsSelectedProps()}
                                        />
                                    </Th>

                                    {headerGroup.headers?.map((column, i) => (
                                        <HeaderColumn
                                            key={`headerCol-${i}`}
                                            column={column}
                                            isBordered={props.isBordered}
                                            size={props.size}
                                            isSorted={column.isSorted}
                                            isSortedDesc={column.isSortedDesc}
                                            disableSortBy={disableSortBy}
                                        />
                                    ))}
                                </Tr>
                            ))}
                    </Thead>

                    <Tbody>
                        {rowMap &&
                            rowMap.length !== 0 &&
                            rowMap.map((row, i) => {
                                prepareRow(row);

                                return (
                                    <TableRow
                                        key={`row-${i}`}
                                        row={row}
                                        accessor={accessor}
                                        isSelected={row.isSelected}
                                        size={props.size}
                                        getToggleRowSelectedProps={
                                            getToggleRowSelectedProps
                                        }
                                        onRowSelect={onRowSelect}
                                        isExpandable={isExpandable}
                                        toggleRowExpanded={toggleRowExpanded}
                                        renderRowSubComponent={
                                            renderRowSubComponent
                                        }
                                        visibleColumns={visibleColumns}
                                    />
                                );
                            })}
                    </Tbody>
                </Table>
            </Box>
            {!hidePagination && data && (
                <Pagination
                    meta={data?.pageInfo}
                    onChangePage={onChangePage}
                    manualPagination={manualPagination}
                    canPreviousPage={canPreviousPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    onChangePageSize={onChangePageSize}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            )}
        </Box>
    );
};

TableComponent.defaultProps = {
    showHeader: true,
    isBordered: true,
    size: 'sm',
    onRowSelect: null,
    showTopPagination: false,
};

const Pagination = ({
    meta,
    onChangePage,
    manualPagination,

    nextPage,
    previousPage,
}) => {
    const [pagination, setPagination] = useState(meta ? meta.currentPage : 1);

    useEffect(() => {
        if (meta && meta.currentPage) {
            setPagination(meta.currentPage);
        }
    }, [meta]);

    return (
        <Container
            display='flex'
            justifyContent='center'
            alignItems='center'
            p={2}
            mt='auto'
            borderTop='0px'
            borderColor='gray.200'
            position='sticky'
            bottom={0}
            variant='card'
            w='100%'
            maxWidth='none'
            rounded={0}
            boxShadow='none'
        >
            <Button
                onClick={() => {
                    previousPage();
                    onChangePage(onChangePage ? meta?.currentPage - 1 : null);
                }}
                disabled={
                    manualPagination
                        ? meta?.currentPage === meta?.from
                        : !meta?.hasPreviousPage
                }
                variant='ghost'
                mr='45px'
                size='sm'
            >
                Previous
            </Button>{' '}
            <Flex align='center'>
                <Box as='span' mr='10px' fontSize='md'>
                    Page {meta?.currentPage}
                </Box>
                {/* <Input
                    type='number'
                    value={pagination}
                    min={1}
                    size='sm'
                    max={meta?.last_page}
                    defaultValue={meta?.current_page}
                    onBlur={(e) => {
                        let v = e.target.value;
                        if (e.target.value > meta?.last_page) {
                            v = meta?.last_page;
                            setPagination(meta?.last_page);
                        }
                        if (e.target.value < 1) {
                            v = 1;
                            setPagination(1);
                        }
                        onChangePage(onChangePage ? v : null);
                    }}
                    w={70}
                    onChange={(e) => setPagination(e.target.value)}
                /> */}
                <Box ml='10px' fontSize='md'>
                    of {meta?.pageCount}
                </Box>
            </Flex>
            {/* <Select
                ml='10px'
                maxWidth='150px'
                size='sm'
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    onChangePageSize(e.target.value);
                }}
            >
                {[10, 20, 30, 40, 50, 100, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </Select> */}
            <Button
                onClick={() => {
                    nextPage();
                    onChangePage(onChangePage ? meta?.currentPage + 1 : null);
                }}
                disabled={
                    manualPagination
                        ? meta?.current_page === meta?.pageCount
                        : !meta.hasNextPage
                }
                size='sm'
                variant='ghost'
                ml='45px'
            >
                Next
            </Button>{' '}
        </Container>
    );
};

export default TableComponent;
