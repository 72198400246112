/* eslint-disable */
const links = [
    // {
    //     group: 'Manage Users',
    //     items: [
    //         {
    //             title: 'A menu item',
    //             href: '/sandboxes',
    //         },
    //     ],
    //     permissions: ['editUsers'],
    // },
    {
        group: 'Courses',
        link: '/courses',
        items: [],
        permissions: ['editCourses'],
    },
    {
        group: 'Course Modules',
        link: '/course-modules',
        items: [],
        permissions: ['editCourses'],
    },
    {
        group: 'Active Courses',
        link: '/user-courses',
        items: [],
        permissions: ['editCourses'],
    },
    {
        group: 'Exam Applications',
        items: [
            {
                title: 'Pending Applications',
                href: '/exam-applications',
            },
            {
                title: 'Completed Exams',
                href: '/confirmed-exam-applications',
            },
        ],
        permissions: ['editCourses'],
    },
];

export default links;
