import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import {
    Flex,
    Text,
    Box,
    Button,
    Alert,
    Image,
    useColorMode,
    Container,
    AlertIcon,
} from '@chakra-ui/react';
import LinkButton from '../../../components/LinkButton';
import { Redirect, useHistory } from 'react-router-dom';
import { Form, Field } from '../../../components/Form';

import { useMutation, gql } from '@apollo/client';
import { generateSlugFromSite, getSite } from '../../../helpers';

import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../state/auth';

const SubmitButton = ({ loading }) => {
    const { submit } = useFormContext();
    return (
        <Button
            size='lg'
            isFullWidth
            variant='primary'
            mb={3}
            type='submit'
            isLoading={loading}
            onClick={() => submit()}
            loadingText='Logging in securely...'
        >
            Login
        </Button>
    );
};

const Login = () => {
    const { currentUser, loading: authLoading } = useRecoilValue(authAtom);
    const now = new Date();
    const { colorMode } = useColorMode();
    const history = useHistory();
    const referrer = history.location.state
        ? history.location.state.from.pathname
        : getSite
        ? getSite.loginRedirect
        : '/dashboard';

    // GRAPHQL

    const LOGIN = gql`
        mutation AdminLogin($email: String!, $password: String!) {
            adminLogin(email: $email, password: $password) {
                token
            }
        }
    `;

    const [
        graphQLLogin,
        { data: loginResponse, loading: graphQLLoading, error: graphQLError },
    ] = useMutation(LOGIN);

    useEffect(() => {
        if (loginResponse && loginResponse.adminLogin) {
            Cookies.set(
                `${generateSlugFromSite()}-token`,
                loginResponse.adminLogin.token,
                { expires: 7 }
            );
            // localStorage.setItem(
            //     `${generateSlugFromSite()}-token`,
            //     JSON.stringify({
            //         token: loginResponse.adminLogin.token,
            //         expiry: now.getTime() + 230400000, // Expires in 8 hours
            //     })
            // );

            window.location.href = getSite.loginRedirect;
        }
    }, [loginResponse, now]);

    const onSubmit = async (data) => {
        await graphQLLogin({
            variables: { ...data },
        })
            .then()
            .catch((err) => console.log(err));
    };

    if (currentUser && !authLoading) {
        return <Redirect to={referrer || getSite.loginRedirect} />;
    }

    return (
        <Flex
            align='center'
            justify='center'
            w='100vw'
            h='100vh'
            direction='column'
            bg='gray.100'
        >
            <Container
                variant='Container'
                w='100%'
                maxWidth={500}
                rounded='20px'
                py='30px'
                boxShadow='md'
            >
                <Flex
                    px={10}
                    align='center'
                    justify='center'
                    w='100%'
                    mb='20px'
                >
                    <Image
                        src={getSite?.clientLogoUrl[colorMode]}
                        objectFit='contain'
                        size='170px'
                        maxHeight='100px'
                    />
                </Flex>
                <Flex align='center' direction='column'>
                    <Flex
                        direction='column'
                        align='center'
                        justify='center'
                        w='100%'
                        maxWidth='400px'
                    >
                        <Text as='h1' fontSize='26px' fontWeight='bold'>
                            Login
                        </Text>
                        <Box py={10} px={5} w='100%'>
                            <Form onSubmit={onSubmit}>
                                <Field
                                    auto={false}
                                    isFullWidth
                                    name='email'
                                    component='text'
                                    variant='filled'
                                    size='lg'
                                    placeholder='Email Address'
                                    type='email'
                                    autoFocus
                                    mb={3}
                                    rules={{ required: true }}
                                />
                                <Field
                                    auto={false}
                                    isFullWidth
                                    name='password'
                                    component='text'
                                    variant='filled'
                                    size='lg'
                                    placeholder='Password'
                                    type='password'
                                    mb={3}
                                    rules={{ required: true }}
                                />

                                <SubmitButton loading={graphQLLoading} />
                            </Form>
                            <LinkButton to='forgot-password'>
                                <Button
                                    variant='ghost'
                                    isFullWidth
                                    variantColor='primary'
                                    size='lg'
                                >
                                    Forgot Password
                                </Button>
                            </LinkButton>
                        </Box>
                    </Flex>

                    {graphQLError && (
                        <Alert status='error' mt='30px'>
                            <AlertIcon />
                            {graphQLError.graphQLErrors[0].message}
                        </Alert>
                    )}
                </Flex>
            </Container>
        </Flex>
    );
};
export default Login;
