const Textarea = {
    defaultProps: {
        //focusBorderColor: 'primary'
    },
    baseStyle: {},
    variants: {
        outline: ({ colorMode }) => ({
            // borderColor: 'gray.300',
            // borderRadius: '4px',
            // _focus: {
            //    boxShadow: 'none',
            //    borderColor: colorMode === 'light' ? 'primary' : 'white'
            // },
            // focusBorderColor: 'primary'
        }),
    },
};

export default Textarea;
