import React from 'react';

import { LinkButton, ListWrapper } from '../../Nucleus';
import { Box, Text, Button, Badge } from '@chakra-ui/react';
import setup from './setup';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';

const FETCHDATA = gql`
    query ListExamApplications(
        $filter: FilterFindManyExamApplicationInput
        $sort: SortFindManyExamApplicationInput
        $page: Int
        $perPage: Int
    ) {
        examApplicationList(
            filter: $filter
            sort: $sort
            page: $page
            perPage: $perPage
        ) {
            items {
                _id
                course {
                    course {
                        title
                    }
                    finished_date
                    course_status
                }
                email
                first_name
                last_name
                status
                failed_date
                passed_date
                scheduled_date
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

const DELETE = gql`
    mutation ($input: [String]) {
        courseDelete(input: $input) {
            total
        }
    }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'User',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row }) => (
                <Text>
                    {row.original.first_name} {row.original.last_name}
                </Text>
            ),
        },
        {
            Header: 'Exam Date',
            accessor: 'scheduled_date',
            disableSortBy: true,
            Cell: ({ value }) =>
                value ? <Text>{dayjs(value).format('DD/MM/YYYY')}</Text> : null,
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ value }) =>
                value === 'FAIL' ? (
                    <Badge colorScheme='red'>Failed</Badge>
                ) : (
                    <Badge colorScheme='green'>Pass</Badge>
                ),
        },
        {
            Header: 'Exam Attempt',
            accessor: 'course.course_status',
            disableSortBy: true,
            Cell: ({ value }) => (
                <>
                    {value === 'FIRST_EXAM_FAILED' && (
                        <Badge colorScheme='green'>First Attempt</Badge>
                    )}
                    {value === 'SECOND_EXAM_FAILED' && (
                        <Badge colorScheme='orange'>Second Attempt</Badge>
                    )}
                    {value === 'FIRST_EXAM_PASSED' && (
                        <Badge colorScheme='green'>First Attempt</Badge>
                    )}
                    {value === 'SECOND_EXAM_PASSED' && (
                        <Badge colorScheme='orange'>Second Attempt</Badge>
                    )}
                </>
            ),
        },
        {
            Header: 'Failed Date',
            accessor: 'failed_date',
            disableSortBy: true,
            Cell: ({ value }) =>
                value ? <Text>{dayjs(value).format('DD/MM/YYYY')}</Text> : null,
        },
        {
            Header: 'Passed Date',
            accessor: 'passed_date',
            disableSortBy: true,
            Cell: ({ value }) =>
                value ? <Text>{dayjs(value).format('DD/MM/YYYY')}</Text> : null,
        },
        {
            Header: '',
            accessor: 'course',

            disableSortBy: true,
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button size='sm' colorScheme='blue'>
                        Edit Application
                    </Button>
                </LinkButton>
            ),
        },
    ];

    const filters = [
        // {
        //     value: 'profession',
        //     type: 'select',
        //     label: 'Profession',
        //     items: data?.listProfessions?.items.map((e) => ({
        //         label: e.title,
        //         value: e._id,
        //     })),
        //     selectValue: 'value',
        // },
        // {
        //     value: 'last_name',
        //     type: 'text',
        //     label: 'Last Name',
        //     isOperator: false,
        // },
        // {
        //    value: 'test',
        //    type: 'select',
        //    isMulti: true,
        //    isOperator: true,
        //    operators: [
        //       {
        //          label: 'Includes',
        //          value: 'in'
        //       },
        //       {
        //          label: 'Excludes',
        //          value: 'nin'
        //       }
        //    ],
        //    label: 'Operator',
        //    items: [
        //       {
        //          value: 'mattprice1@me.com',
        //          label: 'Matt Price'
        //       },
        //       {
        //          value: '665dgsgffd',
        //          label: 'This is another amazing ID'
        //       }
        //    ],
        //    selectValue: 'value'
        // }
    ];

    return (
        <Box>
            <ListWrapper
                setup={setup}
                columns={columns}
                extraMenu={extraMenu}
                gqlFetch={FETCHDATA}
                gqlDelete={DELETE}
                defaultFilter={{
                    _operators: { status: { in: ['PASS', 'FAIL'] } },
                }}
                defaultSort={{}}
                filters={filters}
                showTopPagination={false}
            />
        </Box>
    );
};

export default List;
