const Input = {
    parts: ['field'],
    defaultProps: {
        //focusBorderColor: 'primary',
    },
    baseStyle: {},
    variants: {
        outline: ({ colorMode }) => ({
            field: {
                // borderColor: 'gray.300',
                // borderRadius: '4px',
                // _focus: {
                //   boxShadow: 'none',
                //   borderColor: colorMode === 'light' ? 'primary' : 'white'
                // },
                // focusBorderColor: 'primary'
            },
        }),
        filled: ({ colorMode }) => ({
            field: {
                _focus: {
                    boxShadow: 'none',
                    borderColor: colorMode === 'light' ? 'primary' : 'white',
                    outline: 'none',
                    border: '1px',
                },
                focusBorderColor: 'primary',
            },
        }),
    },
};

export default Input;
