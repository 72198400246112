import React from 'react';
import { Select } from '@chakra-ui/react';

const NativeSelectField = (props) => {
    const {
        onChange,
        onBlur,
        value,
        labelKey,
        valueKey,
        children,
        options,
        ...rest
    } = props;
    return (
        <Select
            onChange={(e) => onChange(e.target.value)}
            isFullWidth
            onBlur={() => {
                onBlur();
            }}
            value={value}
            {...rest}
        >
            {children}
        </Select>
    );
};

export default NativeSelectField;
