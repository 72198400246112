import { createCrudRoutes } from '../../Nucleus';
import List from './List';
import Entry from './';
import Confirmed from './Confirmed';
import setup from './setup';

const crud = createCrudRoutes({
    setup,
    name: setup.title,
    edit: Entry,
    list: List,
    view: null,
});

const otherRoutes = [
    {
        path: '/confirmed-exam-applications',
        title: 'Confirmed Exam Applications',
        exact: true,
        auth: false,
        component: Confirmed,
    },
];

const routes = [...crud, ...otherRoutes];

export default routes;
