import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../state/auth';
import { logout } from '../../helpers';

import { useContext } from 'react';
import { NucleusContext } from '../../Core';

const AuthChecker = ({ children, isPrivate }) => {
    const { currentUserQuery, errors = null } = useContext(NucleusContext);

    const [auth, setAuth] = useRecoilState(authAtom);
    const bypassAuth = process.env.REACT_APP_BYPASS_AUTH;

    // GRAPHQL
    const [fetchUser, { data: userGraphQL, loading, error }] =
        useLazyQuery(currentUserQuery);

    useEffect(() => {
        if (!auth.currentUser) {
            fetchUser();
        }
    }, [errors]);

    useEffect(() => {
        if (bypassAuth === 'false') {
            if (userGraphQL && userGraphQL.currentAdminUser) {
                setAuth((old) => ({
                    ...old,
                    currentUser: userGraphQL.currentAdminUser,
                    loading: false,
                }));
            }
            if ((userGraphQL && !userGraphQL.currentAdminUser?.user) || error) {
                if (isPrivate) {
                    logout();
                }
            }
        } else {
            const testUser = {
                first_name: 'Test',
                last_name: 'User',
                email: 'test@test.com',
                permissions: ['ALL'],
            };
            setAuth((old) => ({
                ...old,
                currentUser: testUser,
                loading: false,
            }));
        }
    }, [error, setAuth, userGraphQL]);

    return loading ? <Spinner /> : children;
};

export default AuthChecker;
