import React, { useState, useEffect } from 'react';
import Field from './Field';
import {
    Text,
    useColorMode,
    Flex,
    Container,
    Button,
    Box,
    Collapse,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import groupBy from 'underscore/modules/groupBy.js';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import ConditionalField from '../../Fields/ConditionalField';

const AutoForm = (props) => {
    const { colorMode } = useColorMode();
    const { type } = useParams();
    const { fields, isFullWidth } = props;
    const [_tabs, setTabs] = useState(null);
    const [isMobile] = useMediaQuery('(max-width: 1025px)');
    const { isOpen, onToggle } = useDisclosure();
    const [display, setDisplay] = useState(0);

    useEffect(() => {
        if (fields) {
            setTabs(groupBy(fields, 'tab'));
        }
    }, [fields]);

    const showField = (field) => {
        if (field.editOnly === undefined && field.createOnly === undefined) {
            return true;
        }
        if (field.editOnly && type === 'edit') {
            return true;
        }
        if (field.createOnly && type === 'create') {
            return true;
        }
        return false;
    };

    return (
        <React.Fragment>
            {_tabs && Object.keys(_tabs)[0] !== 'undefined' ? (
                <Flex
                    w='inherit'
                    overflowX='hidden'
                    align='center'
                    alignItems='flex-start'
                    h='100%'
                >
                    {_tabs && (
                        <Flex
                            w='100%'
                            justify='flex-start'
                            direction={{ base: 'column', xl: 'row' }}
                        >
                            <Flex
                                p='10px'
                                w='100%'
                                display={{ base: 'block', xl: 'none' }}
                            >
                                <Button
                                    isFullWidth
                                    onClick={() => onToggle()}
                                    rightIcon={
                                        isOpen ? (
                                            <MdKeyboardArrowUp fontSize='20px' />
                                        ) : (
                                            <MdKeyboardArrowDown fontSize='20px' />
                                        )
                                    }
                                    variant='outline'
                                    colorScheme='blue'
                                >
                                    Menu
                                </Button>
                            </Flex>
                            <Collapse
                                in={isMobile ? isOpen : true}
                                animateOpacity
                            >
                                <Flex
                                    direction='column'
                                    w={{
                                        base: '100%',
                                        sm: '100%',
                                        xl: '200px',
                                    }}
                                    h='100%'
                                    p={{ base: 0, md: '10px' }}
                                    px={{ base: '10px' }}
                                    overflow='hidden'
                                    flexShrink={0}
                                    flex={1}
                                >
                                    {Object.keys(_tabs).map((t, i) => (
                                        <Box
                                            as='button'
                                            key={`form-tab-${i}`}
                                            mb='5px'
                                            justify='flex-start'
                                            textAlign='left'
                                            rounded='5px'
                                            fontWeight='semibold'
                                            py='8px'
                                            px='8px'
                                            opacity={display === i ? 1 : 0.5}
                                            onClick={() => setDisplay(i)}
                                            bg={
                                                display === i
                                                    ? colorMode === 'light'
                                                        ? 'white'
                                                        : 'whiteAlpha.200'
                                                    : 'transparent'
                                            }
                                            color='gray.800'
                                            fontSize='13px'
                                            boxShadow={
                                                display === i
                                                    ? '0px 0px 3px rgba(0,0,0,0.1)'
                                                    : 'none'
                                            }
                                            _hover={{
                                                bg:
                                                    display !== i &&
                                                    'blackAlpha.200',
                                            }}
                                        >
                                            {t}
                                        </Box>
                                    ))}
                                </Flex>
                            </Collapse>

                            {Object.keys(_tabs).map((t, i) => (
                                <Flex
                                    direction='column'
                                    align='flex-start'
                                    px='10px'
                                    display={display === i ? 'block' : 'none'}
                                    key={`form-tabpanel-${i}`}
                                    w={{
                                        base: '100%',
                                        xl: 'calc(100% - 200px)',
                                    }}
                                >
                                    {Object.values(_tabs)[i].map(
                                        (fGroup, idx) => (
                                            <Container
                                                key={`card=${i}-${idx}`}
                                                p={{
                                                    base: 2,
                                                    sm: 3,
                                                    md: 5,
                                                }}
                                                mt='10px'
                                                w='100%'
                                                maxWidth={
                                                    isFullWidth
                                                        ? 'none'
                                                        : 'none'
                                                }
                                                variant='card'
                                            >
                                                {fGroup.group && (
                                                    <Text
                                                        mb={5}
                                                        fontSize={20}
                                                        id={`test-${i}`}
                                                    >
                                                        {fGroup.group}
                                                    </Text>
                                                )}

                                                {fGroup.component
                                                    ? fGroup.component
                                                    : fGroup.fields.map(
                                                          (field, ix) => {
                                                              if (
                                                                  showField(
                                                                      field
                                                                  )
                                                              ) {
                                                                  return field.condition ? (
                                                                      <ConditionalField
                                                                          key={`field-${ix}`}
                                                                          {...field}
                                                                          index={
                                                                              ix
                                                                          }
                                                                          showField={showField(
                                                                              field
                                                                          )}
                                                                      />
                                                                  ) : (
                                                                      <Field
                                                                          key={`field-${ix}`}
                                                                          {...field}
                                                                          showField={showField(
                                                                              field
                                                                          )}
                                                                      />
                                                                  );
                                                              } else {
                                                                  return null;
                                                              }
                                                          }
                                                      )}
                                            </Container>
                                        )
                                    )}
                                </Flex>
                            ))}
                        </Flex>
                    )}
                </Flex>
            ) : (
                fields &&
                fields.map((group, index) => (
                    <Flex
                        p='10px'
                        w='100%'
                        justify='flex-start'
                        key={`field-${index}`}
                    >
                        <Container
                            key={`card=${index}`}
                            p={{ base: '10px', sm: 5 }}
                            w='100%'
                            maxWidth={'none'}
                            variant='card'
                        >
                            {group.group && (
                                <Text mb={5} fontSize={20} id={`test-${index}`}>
                                    {group.group}
                                </Text>
                            )}

                            {group.fields.map((field, i) => {
                                if (showField(field)) {
                                    return field.condition ? (
                                        <ConditionalField
                                            key={`field-${i}`}
                                            {...field}
                                            index={i}
                                        />
                                    ) : (
                                        <Field
                                            key={`field-${i}`}
                                            {...field}
                                            index={i}
                                            auto
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Container>
                    </Flex>
                ))
            )}
        </React.Fragment>
    );
};

export default AutoForm;
