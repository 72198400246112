import React from 'react';
import { NucleusCore, theme } from './Nucleus';
import Cookies from 'js-cookie';
import config from './config';
import routes from './routes';
import links from './links';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    from,
    gql,
} from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';

const CURRENT_USER = gql`
    query GetAdminUser {
        currentAdminUser {
            user {
                _id
                name
                role {
                    title
                    permissions {
                        _id
                        title
                        code
                    }
                }
            }
        }
    }
`;

const siteName = process.env.REACT_APP_SITENAME;

const App = () => {
    const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = Cookies.get(`${siteName.split(' ').join('_')}-token`);

        return {
            headers: {
                ...headers,
                authorization: token ? `${token}` : '',
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache(),
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    });

    return (
        <ApolloProvider client={client}>
            <ChakraProvider theme={theme}>
                <NucleusCore
                    config={config}
                    routes={routes}
                    links={links}
                    currentUserQuery={CURRENT_USER}
                />
            </ChakraProvider>
        </ApolloProvider>
    );
};

export default App;
