import { atom } from 'recoil';

export const tableAtom = atom({
    key: 'tableState',
    default: {
        selected: [],
        selectedRows: [],
        params: {
            filters: null,
            search: null,
            pagination: 20,
            page: 1,
            sort: null,
        },
    },
});

export const tableFilterAtom = atom({
    key: 'tableFilterState',
    default: {},
});
