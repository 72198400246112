import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
};

const SelectField = (props) => {
    const {
        setActive,
        onChange,
        onBlur,
        value,
        labelKey,
        valueKey,
        options,
        selectValue,
        container,
        ...rest
    } = props;

    useEffect(() => {
        if (value) {
            if (selectValue) {
                if (props.isMulti) {
                    // Check if the options are objects.  If so, we need to send back string array
                    const object = typeof value[0] === 'object';
                    if (object) {
                        let result = value.map((r) => r[valueKey]);

                        onChange(result);
                    }
                } else {
                    const object = typeof value === 'object';
                    if (object && value !== value[valueKey]) {
                        onChange(value[valueKey]);
                    }
                }
            }
        }
    }, [value, onChange, selectValue, props.isMulti, valueKey]);

    const renderOptions = () => {
        const _options =
            options &&
            options.map((o) => {
                if (typeof o === 'object') {
                    return {
                        label: o[labelKey],
                        value: o[valueKey],
                    };
                } else {
                    return {
                        label: o,
                        value: o,
                    };
                }
            });
        return _options;
    };

    const handleChange = (e) => {
        let value = e;

        if (selectValue) {
            if (props.isMulti) {
                let result = e && e.length !== 0 ? e.map((r) => r.value) : [];
                onChange(result);
            } else {
                onChange(e ? e[selectValue] : null);
            }
        } else {
            onChange(value);
        }
    };

    const formattedValue = () => {
        if (props.isMulti) {
            let _val = [];

            renderOptions().forEach((option) => {
                if (
                    value &&
                    value.some((v) => {
                        if (typeof v === 'object') {
                            return v[selectValue] === option.value;
                        } else {
                            return v === option.value;
                        }
                    })
                ) {
                    _val.push(option);
                }
            });

            return _val;
        } else {
            if (valueKey && typeof value === 'object') {
                return renderOptions()
                    ? renderOptions().find((o) => o.value === value[valueKey])
                    : null;
            } else {
                return renderOptions().find((o) => o.value === value);
            }
        }
    };

    return (
        <Select
            styles={customStyles}
            onChange={(e) => handleChange(e)}
            isFullWidth
            onBlur={() => {
                onBlur && onBlur();
            }}
            value={selectValue ? formattedValue() : value}
            options={renderOptions()}
            menuPortalTarget={
                !container && typeof window !== 'undefined'
                    ? document.body
                    : container
            }
            {...rest}
        />
    );
};

export default SelectField;
