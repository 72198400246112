import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHttp } from '../../Nucleus';

const AddLearner = () => {
    const { id } = useParams();
    const toast = useToast();
    const Http = useHttp();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit } = useForm({
        shouldUseNativeValidation: true,
    });
    const onSubmit = async (data) => {
        setLoading(true);
        Http.post('/api/auth/register', {
            users: [
                {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    status: 'Booked in',
                    booking_reference: '00000',
                    booked_by: 'courses@marbletraining.co.uk',
                    course: id,
                },
            ],
        }).then((res) => {
            setLoading(false);
            toast({ status: 'success', title: 'Learner added successfully' });
        });
    };

    return (
        <Flex w='100%' px='10px' pt='10px'>
            <Button colorScheme='blue' onClick={() => onOpen()}>
                Add Learner
            </Button>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalCloseButton />
                        <ModalHeader>Add Learner</ModalHeader>
                        <ModalBody>
                            <Input
                                placeholder='First Name'
                                {...register('first_name', {
                                    required: 'Please enter the first name.',
                                })}
                                mb='5px'
                            />
                            <Input
                                placeholder='Last Name'
                                {...register('last_name', {
                                    required: 'Please enter the last name.',
                                })}
                                mb='5px'
                            />
                            <Input
                                placeholder='Learner Email Address'
                                {...register('email', {
                                    required: 'Please enter the email address',
                                })}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isDisabled={loading}
                                isLoading={loading}
                                colorScheme='blue'
                                type='submit'
                            >
                                Submit
                            </Button>
                        </ModalFooter>{' '}
                    </form>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default AddLearner;
