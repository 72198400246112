import React, { useEffect } from 'react';
import { Field, EditView } from '../../Nucleus';
import { gql, useLazyQuery } from '@apollo/client';
import setup from './setup';
import { Flex, Spinner, Text, Image } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const FETCH = gql`
    query FetchExamApplication($filter: FilterFindOneExamApplicationInput!) {
        examApplicationGet(filter: $filter) {
            first_name
            last_name
            full_name
            scheduled_date
            email
            dob
            photo_id
            exam_date
            status
            passed_date
            failed_date
        }
    }
`;

const FETCH_COURSE_EXAM_DATES = gql`
    query FetchExamDates($filter: FilterFindOneUserCourseInput!) {
        userCourseGet(filter: $filter) {
            course {
                exam_dates {
                    date
                    slots
                    time
                }
            }
        }
    }
`;

const EDIT = gql`
    mutation Mutation(
        $record: UpdateOneExamApplicationInput!
        $filter: FilterUpdateOneExamApplicationInput!
    ) {
        examApplicationEdit(record: $record, filter: $filter) {
            _id
        }
    }
`;

const CREATE = gql`
    mutation Mutation($record: CreateOneExamApplicationInput!) {
        examApplicationCreate(record: $record) {
            _id
        }
    }
`;

const EditForm = () => {
    const [fetchData, { data }] = useLazyQuery(FETCH);
    const [fetchCourseDates, { data: courseDates }] = useLazyQuery(
        FETCH_COURSE_EXAM_DATES
    );

    const application = data?.examApplicationGet;

    useEffect(() => {
        if (data) {
            fetchCourseDates({
                variables: {
                    filter: { _id: data.examApplicationGet.course },
                },
            });
        }
    }, [data]);

    return (
        <EditView
            fetchData={fetchData}
            data={data}
            setup={setup}
            gqlFetch={FETCH}
            gqlEdit={EDIT}
            gqlCreate={CREATE}
        >
            <Flex w='100%' bg='gray.100'>
                {data && data.examApplicationGet ? (
                    <Flex p='20px' w='100%'>
                        <Flex w='100%'>
                            <Flex
                                w='100%'
                                direction='column'
                                bg='white'
                                rounded='md'
                                p='25px'
                            >
                                <Text
                                    fontSize='1.2em'
                                    fontWeight='bold'
                                    mb='15px'
                                >
                                    Applicant
                                </Text>
                                <Flex direction='column'>
                                    <Text>
                                        {application.first_name}{' '}
                                        {application.middle_name}{' '}
                                        {application.last_name}
                                    </Text>
                                    <Text
                                        as='a'
                                        color='blue.500'
                                        href={`mailto:${application.email}`}
                                    >
                                        {application.email}
                                    </Text>
                                    <Text mt='20px'>
                                        DOB:{' '}
                                        {dayjs(application.dob).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </Text>
                                    <Image
                                        my='25px'
                                        src={application.photo_id.url}
                                        w='100%'
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex
                            direction='column'
                            w='400px'
                            bg='white'
                            rounded='md'
                            ml='25px'
                            p='25px'
                        >
                            <Text fontSize='1.2em' fontWeight='bold' mb='15px'>
                                Confirm Exam
                            </Text>

                            <Text mb='25px'>
                                Preferred Date:{' '}
                                {dayjs(application.exam_date).format(
                                    'DD/MM/YYYY - HH:mm'
                                )}
                            </Text>

                            <Field
                                name='scheduled_date'
                                component='nativeSelect'
                                label='Select date of exam'
                                rules={{ required: true }}
                            >
                                <option value={null}>Select booked date</option>
                                {courseDates &&
                                    courseDates.userCourseGet.course.exam_dates.map(
                                        (d, i) => (
                                            <option
                                                value={d.date}
                                                key={`date-option-${i}`}
                                                disabled={d.slots === 0}
                                            >
                                                {dayjs(d.date).format(
                                                    'dddd Do MMMM - HH:mm'
                                                )}{' '}
                                                - {d.slots} Slots
                                            </option>
                                        )
                                    )}
                            </Field>

                            <Field
                                name='status'
                                label='Exam Status'
                                component='nativeSelect'
                            >
                                <option value={null}>Select Result</option>
                                <option value='PASS'>Exam Passed</option>
                                <option value='FAIL'>Exam Failed</option>
                            </Field>

                            <DateFields />
                        </Flex>
                    </Flex>
                ) : (
                    <Spinner />
                )}
            </Flex>
        </EditView>
    );
};

const DateFields = () => {
    const { setValue } = useFormContext();
    const status = useWatch({ name: 'status' });

    useEffect(() => {
        if (status === 'PASS') {
            setValue('failed_date', null);
        }
        if (status === 'FAIL') {
            setValue('passed_date', null);
        }
    }, [status]);

    return (
        <Flex direction='column' w='100%'>
            {status === 'PASS' && (
                <Field
                    name='passed_date'
                    component='date'
                    type='date'
                    label='PASS Date'
                    rules={{ required: true }}
                />
            )}
            {status === 'FAIL' && (
                <Field
                    name='failed_date'
                    component='date'
                    type='date'
                    label='FAIL Date'
                    rules={{ required: true }}
                />
            )}
        </Flex>
    );
};

export default EditForm;
