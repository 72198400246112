import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import routes from './routes';
import { useRecoilValue } from 'recoil';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Menu from '../components/Menu';
import SideBar from '../components/Sidebar';
import MobileHeader from '../components/MobileHeader';

import { NucleusContext } from '../Core';
import { authAtom } from '../state/auth';

const Routes = ({ clientRoutes }) => {
    const { links } = useContext(NucleusContext);
    const { currentUser } = useRecoilValue(authAtom);
    return (
        <React.Fragment>
            {currentUser && <MobileHeader />}
            <Flex w='100vw'>
                {currentUser && <SideBar />}
                {currentUser && <Menu links={links} />}

                <Flex
                    direction='column'
                    w='100%'
                    borderLeft='1px'
                    borderColor='gray.300'
                >
                    <Switch>
                        {clientRoutes &&
                            clientRoutes.map((route, index) => {
                                if (route.auth) {
                                    return (
                                        <PrivateRoute
                                            key={`route-${index}`}
                                            {...route}
                                        />
                                    );
                                }
                                return (
                                    <PublicRoute
                                        key={`route-${index}`}
                                        {...route}
                                    />
                                );
                            })}
                        {routes.map((route, index) => {
                            if (route.auth) {
                                return (
                                    <PrivateRoute
                                        key={`route-${index}`}
                                        {...route}
                                    />
                                );
                            }
                            return (
                                <PublicRoute
                                    key={`route-${index}`}
                                    {...route}
                                />
                            );
                        })}
                    </Switch>
                </Flex>
            </Flex>
        </React.Fragment>
    );
};

export default Routes;
