import React from 'react';
import { Flex, useColorMode, Image, IconButton } from '@chakra-ui/react';
import { getSite } from '../../helpers';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { useSetRecoilState } from 'recoil';
import { menuAtom } from '../../state/global';

const MobileHeader = () => {
    const site = getSite;
    const { colorMode } = useColorMode();
    const setMenu = useSetRecoilState(menuAtom);

    return (
        <Flex
            align='center'
            justify='space-between'
            w='100%'
            h='50px'
            bg='global.elementBg'
            borderBottom='1px'
            borderColor='global.borderColour'
            px='10px'
            position='relative'
            zIndex={1}
            display={{ base: 'flex', xl: 'none' }}
        >
            <IconButton
                variant='link'
                fontSize='20px'
                onClick={() => setMenu(true)}
            >
                <RiMenuUnfoldLine />
            </IconButton>
            <Image
                h='30px'
                objectFit='contain'
                src={site?.clientLogoUrl[colorMode]}
            />
        </Flex>
    );
};

export default MobileHeader;
