import React, { useEffect, useState, forwardRef } from 'react';

import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { InputGroup, Input, InputLeftElement } from '@chakra-ui/react';

import { FiCalendar } from 'react-icons/fi';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

require('react-datepicker/dist/react-datepicker.min.css');

const DatePickerComponent = (props) => {
    const {
        onChange,
        value,
        isDisabled,
        disableFuture,
        disablePast,
        inputProps,
        minDate,
        maxDate,
        clearable,
        onBlur,
        variant,
        size,
        placeholder,
        dateFormat,
        ...rest
    } = props;

    const [selectedDate, handleDateChange] = useState();

    const changeValue = (val) => {
        if (val) {
            handleDateChange(val);
            onChange(dayjs(val).toDate());
        } else {
            handleDateChange(null);
            onChange(null);
        }
    };

    /* eslint-disable */
    useEffect(() => {
        if (selectedDate !== value) {
            handleDateChange(value);
        }
    }, [value]);

    const Field = forwardRef(({ onClick }, ref) => {
        return (
            <InputGroup ref={ref} size={size}>
                <InputLeftElement children={<FiCalendar />} />

                <Input
                    placeholder={placeholder ? placeholder : 'Select Date'}
                    isDisabled={isDisabled}
                    onChange={null}
                    variant={variant}
                    size={size}
                    onFocus={() => onClick()}
                    defaultValue={
                        selectedDate
                            ? dayjs(selectedDate).format(
                                  dateFormat ? dateFormat : 'DD/MM/YYYY'
                              )
                            : null
                    }
                    ref={ref}
                    bg='white'
                    {...inputProps}
                    value={
                        selectedDate
                            ? dayjs(selectedDate).format(
                                  dateFormat ? dateFormat : 'DD/MM/YYYY'
                              )
                            : null
                    }
                />
            </InputGroup>
        );
    });

    return (
        <Styles>
            <DatePicker
                label='Date'
                selected={selectedDate ? dayjs(selectedDate).toDate() : null}
                minDate={minDate}
                maxDate={maxDate}
                closeOnScroll={true}
                onChange={(v) => changeValue(v ? v : null)}
                animateYearScrolling={false}
                customInput={<Field />}
                disabled={isDisabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                isClearable={clearable}
                emptyLabel={placeholder ? placeholder : 'Select Date'}
                showPopperArrow={false}
                disabledKeyboardNavigation
                {...rest}
            />
        </Styles>
    );
};

DatePickerComponent.defaultProps = {
    autoOk: true,
    format: 'DD/MM/YYYY',
    inputProps: {
        variant: 'outline',
    },
    clearable: true,
    onChange: (date) => console.log(date),
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: 'select',
};

DatePickerComponent.displayName = 'DatePicker';

DatePickerComponent.propTypes = {
    /**
     * Whether the datepicker should close on selection of a date
     */
    autoOk: PropTypes.bool,
    /**
     * The date format
     */
    format: PropTypes.string,
    /**
     * Function to fire when a date is selected.  Passes a moment object
     */
    onChange: PropTypes.func,
    /**
     * The value
     */
    value: PropTypes.string,
    /**
     * Disable future dates
     */
    disableFuture: PropTypes.bool,
    /**
     * Disable past dates
     */
    disablePast: PropTypes.bool,
    /**
     * The props for the input element (eg variant, width)
     */
    inputProps: PropTypes.object,
    /**
     * Max selectable date (ParsableDate)
     */
    maxDate: PropTypes.string,
    /**
     * Min selectable date (ParsableDate)
     */
    minDate: PropTypes.string,
    /**
     * The variant of the input field
     */
    variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
    /**
     * The size of the input field
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default DatePickerComponent;

export const Styles = styled.div`
    .react-datepicker {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .react-datepicker__day {
            padding: 3px;
            width: 30px;
            height: 30px;
            line-height: 30px;

            &.react-datepicker__day--selected {
                background-color: rgba(0, 0, 0, 0.9);
            }
        }

        .react-datepicker__header {
            border-bottom: 3px solid rgba(0, 0, 0, 0.05);
        }

        .react-datepicker__day-name {
            width: 30px;
            padding: 3px;
            font-weight: 500;
        }

        .react-datepicker__header__dropdown--select {
            margin-top: 5px;
        }

        .react-datepicker__day--today {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 100%;
        }
        .react-datepicker__month-select,
        .react-datepicker__year-select {
            height: 25px;
            border-radius: 3px;
            padding-left: 5px;
            padding-right: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
`;
