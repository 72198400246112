import React, { useMemo } from 'react';
import { AutoFields, EditView } from '../../Nucleus';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import setup from './setup';

import * as yup from 'yup';

const FETCH = gql`
    query FetchCourseModule($filter: FilterFindOneCourseModuleInput!) {
        courseModuleGet(filter: $filter) {
            title
            order
            course_id {
                title
                _id
            }
            description
            videos {
                title
                video
            }
            quiz {
                question
                question_help
                is_multiple
                options {
                    answer
                    image_answer
                    isCorrect
                }
            }
        }
    }
`;

const FETCH_COURSES = gql`
    query FetchCourses {
        courseList {
            items {
                _id
                title
            }
        }
    }
`;

const EDIT = gql`
    mutation Mutation(
        $record: UpdateOneCourseModuleInput!
        $filter: FilterUpdateOneCourseModuleInput!
    ) {
        courseModuleEdit(record: $record, filter: $filter) {
            record {
                _id
            }
        }
    }
`;

const CREATE = gql`
    mutation Mutation($record: CreateOneCourseModuleInput!) {
        courseModuleCreate(record: $record) {
            record {
                _id
            }
        }
    }
`;

const schema = yup.object().shape({
    title: yup.string().required('Required'),
});

const EditForm = () => {
    const [fetchData, { data }] = useLazyQuery(FETCH);

    const { data: courses } = useQuery(FETCH_COURSES);

    const fields = useMemo(
        () => [
            {
                group: 'Main Details',
                tab: 'Main Details',
                fields: [
                    {
                        name: 'course_id',
                        component: 'select',
                        placeholder: 'Course',
                        label: 'Course',
                        rules: { required: true },
                        options: courses ? courses.courseList.items : [],
                        labelKey: 'title',
                        valueKey: '_id',
                        selectValue: 'value',
                    },
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        rules: { required: true },
                    },
                    {
                        name: 'order',
                        component: 'number',
                        placeholder: 'Order',
                        label: 'Order',
                        rules: { required: true },
                    },
                    {
                        name: 'description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Videos',
                tab: 'Videos',
                fields: [
                    {
                        name: 'videos',
                        component: 'repeater',
                        placeholder: 'Videos',
                        label: 'Videos',
                        rules: { required: true },
                        fieldArray: [
                            {
                                name: 'title',
                                component: 'text',
                                placeholder: 'Title',
                                label: 'Title',
                                rules: { required: true },
                            },
                            {
                                name: 'video',
                                component: 'video',
                                placeholder: 'Video',
                                label: 'Video',
                                rules: { required: true },
                            },
                        ],
                    },
                ],
            },
            {
                group: 'Quiz',
                tab: 'Quiz',
                fields: [
                    {
                        name: 'quiz',
                        component: 'repeater',
                        placeholder: 'Quiz Questions',
                        label: 'Quiz Questions',
                        rules: { required: true },
                        layout: 'vertical',
                        fieldArray: [
                            {
                                name: 'question',
                                component: 'text',
                                placeholder: 'Question',
                                label: 'Question',
                                rules: { required: true },
                            },
                            {
                                name: 'question_images',
                                component: 'file',
                                placeholder: 'Question Images',
                                label: 'Question Images',
                            },
                            {
                                name: 'question_help',
                                component: 'text',
                                placeholder: 'Question Help',
                                label: 'Question Help',
                            },
                            {
                                name: 'is_multiple',
                                component: 'switch',
                                placeholder:
                                    'Does this question require multiple answers?',
                                label: 'Does this question require multiple answers?',
                                size: 'lg',
                            },
                            {
                                name: 'options',
                                component: 'repeater',
                                placeholder: 'Answer Options',
                                label: 'Answer Options',
                                rules: { required: true },
                                fieldArray: [
                                    {
                                        name: 'answer',
                                        component: 'textarea',
                                        placeholder: 'Text Answer',
                                        label: 'Text Answer',
                                    },
                                    {
                                        name: 'image_answer',
                                        component: 'file',
                                        placeholder: 'Image Answer',
                                        label: 'Image Answer',
                                    },
                                    {
                                        name: 'isCorrect',
                                        component: 'switch',
                                        placeholder: 'Is correct answer?',
                                        label: 'Is correct answer?',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
        [courses]
    );

    return (
        <EditView
            validationSchema={schema}
            fetchData={fetchData}
            data={data}
            setup={setup}
            gqlFetch={FETCH}
            gqlEdit={EDIT}
            gqlCreate={CREATE}
        >
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
