const modelName = 'Course Modules';
const modelNameSingular = 'Course Module';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: true,
    canFilter: true,
    canSelect: true,
    accessor: '_id',
};

export default setup;
