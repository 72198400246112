import React, { useEffect, useState } from 'react';
import {
    Flex,
    Container,
    Text,
    Box,
    Button,
    useColorMode,
    useToast,
    Image,
    Alert,
} from '@chakra-ui/react';
import LinkButton from '../../../components/LinkButton';
import { useMutation, gql } from '@apollo/client';
import { Form, Field } from '../../../components/Form';
import { useFormContext } from 'react-hook-form';
import { getSite } from '../../../helpers';

const SubmitButton = ({ loading }) => {
    const { submit } = useFormContext();
    return (
        <Button
            size='lg'
            isFullWidth
            colorScheme='blue'
            mb={3}
            type='submit'
            isLoading={loading}
            onClick={() => submit()}
            loadingText='Loading...'
        >
            Request Reset Link
        </Button>
    );
};

const RESET = gql`
    mutation ForgotPassword($email: String) {
        forgotAdminPassword(email: $email) {
            email
        }
    }
`;

const ForgotPassword = () => {
    const { colorMode } = useColorMode();
    const toast = useToast();

    const [handleSubmit, { data, loading, error }] = useMutation(RESET);

    const onSubmit = async (data) => {
        try {
            await handleSubmit({ variables: data });
        } catch (e) {
            toast({
                status: 'error',
                title: 'Error',
                description: `${error}`,
            });
        }
    };

    useEffect(() => {
        if (data && data.forgotAdminPassword) {
            toast({
                status: 'success',
                title: 'Success',
                description: `An email has been sent to ${data.forgotAdminPassword.email}.`,
            });
        }
    }, [data, toast]);

    return (
        <Flex
            align='center'
            justify='center'
            h='100vh'
            w='100vw'
            direction='column'
        >
            <Container
                variant='Container'
                w='100%'
                maxWidth={500}
                rounded='20px'
                py='30px'
                boxShadow='md'
            >
                <Flex
                    px={10}
                    align='center'
                    justify='center'
                    w='100%'
                    mb='20px'
                >
                    <Image
                        src={getSite?.clientLogoUrl[colorMode]}
                        objectFit='contain'
                        size='170px'
                        maxHeight='100px'
                    />
                </Flex>
                <Flex
                    direction='column'
                    align='center'
                    justify='center'
                    w='100%'
                >
                    <Text as='h1' fontSize='26px' fontWeight='bold'>
                        Password Reset
                    </Text>

                    <Box py={10} px={10} w='100%'>
                        <Form onSubmit={onSubmit}>
                            <Field
                                name='email'
                                component='text'
                                variant='filled'
                                size='lg'
                                placeholder='Email Address'
                                type='email'
                                autoFocus
                                mb={3}
                                auto={false}
                                rules={{ required: true }}
                            />

                            <SubmitButton loading={loading} />
                        </Form>
                        <LinkButton to='/'>
                            <Button
                                variant='ghost'
                                isFullWidth
                                variantColor='primary'
                                size='lg'
                            >
                                Return to Login
                            </Button>
                        </LinkButton>
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
};
export default ForgotPassword;
