import React, { Suspense } from 'react';
import Kernel from './Kernel';

import { RecoilRoot } from 'recoil';

import { CloudinaryContext } from 'cloudinary-react';

import { ChakraProvider, Spinner } from '@chakra-ui/react';
import theme from '../theme';
import { createContext } from 'react';

export const NucleusContext = createContext();

const NucleusCore = (props) => {
    const { routes, config, links, currentUserQuery, errors } = props;

    return (
        <NucleusContext.Provider
            value={{ routes, config, links, currentUserQuery, errors }}
        >
            <ErrorBoundary>
                <RecoilRoot>
                    <CloudinaryContext
                        cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
                    >
                        <Suspense fallback={<Spinner />}>
                            <ChakraProvider theme={theme}>
                                <Kernel />
                            </ChakraProvider>
                        </Suspense>
                    </CloudinaryContext>
                </RecoilRoot>
            </ErrorBoundary>
        </NucleusContext.Provider>
    );
};

export default NucleusCore;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
