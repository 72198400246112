import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Grid,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  useDisclosure,
  Text,
} from '@chakra-ui/react';

import Field from '../../components/Form/Field';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';

import ConditionalField from '../ConditionalField';
import { BiArrowToBottom, BiArrowToTop, BiTrashAlt } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';

import _get from 'lodash.get';

export const Repeater = (props) => {
  const {
    fieldArray,
    name,
    singleName,
    keyName,
    label,
    max,
    isRepeater,
    defaultValue,
    layout = 'horizontal',
  } = props;
  const { control } = useFormContext();

  const { fields, append, remove, move, insert } = useFieldArray({
    name: name,
    control,
    defaultValue: defaultValue ? defaultValue : [],
    keyName: keyName || 'id',
  });

  const [isExpanded, setIsExpanded] = useState({ field: null, index: 0 });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [indexToRemove, setIndexToRemove] = useState(null);

  useEffect(() => {
    if (indexToRemove || indexToRemove === 0) {
      if (window.confirm('Do you really want to delete this item?')) {
        remove(indexToRemove);
        setIndexToRemove(null);
      } else {
        setIndexToRemove(null);
      }
    }
  }, [indexToRemove]);

  const handleClose = () => {
    setIsExpanded({ field: {}, index: null });
    onClose();
  };

  return (
    <Box w='100%' p={isRepeater ? '7px' : '0px'}>
      <FormLabel mb='10px'>
        {label} {max && `(maximum ${max} items)`}
      </FormLabel>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size='xl'
        isCentered
        scrollBehavior='inside'
      >
        <ModalOverlay />

        <ModalCloseButton />
        <ModalContent maxWidth='1000px' p='15px' overflowY='auto'>
          <ModalCloseButton />
          {fieldArray &&
            fieldArray.map((_field, i) =>
              _field.condition ? (
                <ConditionalField
                  {..._field}
                  key={`field-${isExpanded.index}-${i}`}
                  parentName={`${name}.${isExpanded.index}`}
                  isRepeater
                  index={i}
                  name={`${name}.${isExpanded.index}.${_field.name}`}
                  component={_field.component}
                  size='sm'
                  defaultValue={
                    _field.defaultValue
                      ? _field.defaultValue
                      : _get(isExpanded.field, _field.name)
                  }
                  withPortal
                />
              ) : (
                <Item
                  _field={_field}
                  field={isExpanded.field}
                  i={i}
                  index={isExpanded.index}
                  name={name}
                  isRepeater
                />
              )
            )}
        </ModalContent>
      </Modal>

      {fields &&
        fields.map((field, index) => (
          <Flex
            bg='gray.50'
            rounded='md'
            align='center'
            border='1px'
            borderColor='gray.200'
            px={isRepeater ? 0 : '15px'}
            mb='10px'
            _hover={{
              bg: 'blue.50',
              cursor: 'pointer',
              borderColor: 'blue.200',
            }}
          >
            <Grid
              templateColumns={
                layout === 'vertical'
                  ? '1fr'
                  : isExpanded === index
                  ? '1fr'
                  : `repeat(${fieldArray.length}, minmax(min-content, 400px))`
              }
              gridAutoRows='minmax(60px, auto)'
              rounded='lg'
              gap='5px 5px'
              display={{ base: 'flex', md: 'grid' }}
              flexDirection='column'
              onClick={() => {
                if (isRepeater !== true) {
                  setIsExpanded({ field, index });
                  onOpen();
                }
              }}
              w='100%'
            >
              {isRepeater ? (
                fieldArray &&
                fieldArray.map((_field, i) =>
                  _field.condition ? (
                    <ConditionalField
                      {..._field}
                      key={`field-${isExpanded.index}-${i}`}
                      parentName={`${name}.${isExpanded.index}`}
                      isRepeater
                      index={i}
                      name={`${name}.${isExpanded.index}.${_field.name}`}
                      component={_field.component}
                      size='sm'
                      defaultValue={
                        _field.defaultValue
                          ? _field.defaultValue
                          : _get(isExpanded.field, _field.name)
                      }
                      withPortal
                    />
                  ) : (
                    <Item
                      _field={_field}
                      field={field}
                      i={i}
                      index={index}
                      name={name}
                      isRepeater
                    />
                  )
                )
              ) : (
                <Flex w='100%' h='100%' align='center' fontSize='0.9em'>
                  <FieldLabel index={index} name={name} />
                </Flex>
              )}
            </Grid>
            <Flex ml='auto'>
              <Menu title='Open Dropdown'>
                <MenuButton
                  as={IconButton}
                  rounded='full'
                  variant='ghost'
                  size='sm'
                  icon={<FiSettings fontSize='14px' opacity={0.7} />}
                />
                <MenuList>
                  <MenuItem
                    onClick={() => insert(index, {})}
                    icon={<AiOutlineAppstoreAdd />}
                  >
                    Add Item Above
                  </MenuItem>
                  <MenuItem
                    icon={<AiOutlineAppstoreAdd />}
                    onClick={() => insert(index + 1, {})}
                  >
                    Add Item Below
                  </MenuItem>
                  <MenuItem
                    icon={<BiArrowToTop fontSize='16px' />}
                    onClick={() => move(index, index - 1)}
                  >
                    Move Up
                  </MenuItem>
                  <MenuItem
                    icon={<BiArrowToBottom fontSize='16px' />}
                    onClick={() => move(index, index + 1)}
                  >
                    Move Down
                  </MenuItem>
                </MenuList>
              </Menu>

              <IconButton
                rounded='full'
                onClick={() => setIndexToRemove(index)}
                variant='ghost'
                size='sm'
                icon={<BiTrashAlt fontSize='16px' opacity={0.7} />}
              />
            </Flex>
          </Flex>
        ))}

      {/* {fields &&
                fields.map((field, index) => (
                    <Flex align='flex-start' key={field[keyName || 'id']}>
                        <Box
                            bg={theme.colors.secondaryLight}
                            border='1px'
                            borderColor={
                                colorMode === 'light'
                                    ? lighten(0.3, theme.colors.secondary)
                                    : 'whiteAlpha.400'
                            }
                            rounded='lg'
                            mb='20px'
                            w='100%'
                            p='7px'
                        >
                            <ScrollContainer>
                                <Grid
                                    templateColumns={
                                        layout === 'vertical'
                                            ? '1fr'
                                            : isExpanded === index
                                            ? '1fr'
                                            : `repeat(${fieldArray.length}, minmax(min-content, 400px))`
                                    }
                                    gridAutoRows='minmax(60px, auto)'
                                    rounded='lg'
                                    gap='5px 5px'
                                    display={{ base: 'flex', md: 'grid' }}
                                    flexDirection='column'
                                >
                                    {fieldArray &&
                                        fieldArray.map((_field, i) =>
                                            _field.condition ? (
                                                <ConditionalField
                                                    {..._field}
                                                    key={`field-${index}-${i}`}
                                                    parentName={`${name}.${index}`}
                                                    isRepeater
                                                    index={i}
                                                    name={`${name}.${index}.${_field.name}`}
                                                    component={_field.component}
                                                    size='sm'
                                                    defaultValue={
                                                        _field.defaultValue
                                                            ? _field.defaultValue
                                                            : _get(
                                                                  field,
                                                                  _field.name
                                                              )
                                                    }
                                                    withPortal
                                                />
                                            ) : isExpanded === index ? (
                                                <Item
                                                    _field={_field}
                                                    field={field}
                                                    i={i}
                                                    index={index}
                                                    name={name}
                                                />
                                            ) : (
                                                i === 0 && (
                                                    <Item
                                                        _field={_field}
                                                        field={field}
                                                        i={i}
                                                        index={index}
                                                        name={name}
                                                    />
                                                )
                                            )
                                        )}
                                </Grid>
                            </ScrollContainer>
                        </Box>
                        <Flex ml='3px' direction='column'>
                            <Menu title='Open Dropdown'>
                                <MenuButton
                                    as={IconButton}
                                    rounded='full'
                                    variant='ghost'
                                    size='sm'
                                    icon={
                                        <FiSettings
                                            fontSize='14px'
                                            opacity={0.7}
                                        />
                                    }
                                />
                                <MenuList>
                                    <MenuItem
                                        onClick={() => insert(index, {})}
                                        icon={<AiOutlineAppstoreAdd />}
                                    >
                                        Add Item Above
                                    </MenuItem>
                                    <MenuItem
                                        icon={<AiOutlineAppstoreAdd />}
                                        onClick={() => insert(index + 1, {})}
                                    >
                                        Add Item Below
                                    </MenuItem>
                                    <MenuItem
                                        icon={<BiArrowToTop fontSize='16px' />}
                                        onClick={() => move(index, index - 1)}
                                    >
                                        Move Up
                                    </MenuItem>
                                    <MenuItem
                                        icon={
                                            <BiArrowToBottom fontSize='16px' />
                                        }
                                        onClick={() => move(index, index + 1)}
                                    >
                                        Move Down
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => remove(index)}
                                        icon={
                                            <BiTrashAlt
                                                fontSize='16px'
                                                opacity={0.7}
                                            />
                                        }
                                    >
                                        Remove Item
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                            <PopConfirm
                                onConfirm={() => remove(index)}
                                title='Are you sure you want to remove this item?'
                            >
                                <IconButton
                                    rounded='full'
                                    variant='ghost'
                                    size='sm'
                                    icon={
                                        <BiTrashAlt
                                            fontSize='16px'
                                            opacity={0.7}
                                        />
                                    }
                                />
                            </PopConfirm>
                            <IconButton
                                rounded='full'
                                variant='ghost'
                                size='sm'
                                onClick={() =>
                                    setIsExpanded(
                                        isExpanded === index ? null : index
                                    )
                                }
                                icon={
                                    <BiExpand fontSize='16px' opacity={0.7} />
                                }
                            />
                        </Flex>
                    </Flex>
                ))} */}
      <Button
        onClick={() => append({})}
        leftIcon={<AiOutlineAppstoreAdd />}
        variant='outline'
        colorScheme='blue'
        isDisabled={fields.length >= max}
        size='sm'
        mb='10px'
      >
        Add {singleName || 'item'}
      </Button>
    </Box>
  );
};

const Item = ({ _field, name, index, i, field, isRepeater }) => {
  return (
    <Box
      borderRight={isRepeater ? '1px' : 0}
      borderColor='gray.300'
      key={`field-${index}-${i}`}
      isFile={_field.component === 'file'}
      overflowY='visible'
      position='relative'
      minWidth={_field.component === 'file' ? '400px' : '100px'}
    >
      <Field
        {..._field}
        parentName={`${name}.${index}`}
        isRepeater
        index={i}
        name={`${name}.${index}.${_field.name}`}
        component={_field.component}
        size='sm'
        defaultValue={
          _field.defaultValue ? _field.defaultValue : _get(field, _field.name)
        }
        withPortal
        bg='white'
      />
    </Box>
  );
};

const FieldLabel = ({ name, index }) => {
  const { watch } = useFormContext();

  const field = watch(`${name}[${index}]`);
  const fields = Object.keys(field);

  const label = watch(`${name}[${index}][${fields[1]}]`);

  return <Text>{label}</Text>;
};
