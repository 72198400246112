import React from 'react';

import { LinkButton, ListWrapper } from '../../Nucleus';
import { Box, Text } from '@chakra-ui/react';
import setup from './setup';
import { gql } from '@apollo/client';

const FETCHDATA = gql`
    query ListCourseModules(
        $filter: FilterFindManyCourseModuleInput
        $sort: SortFindManyCourseModuleInput
        $page: Int
        $perPage: Int
    ) {
        courseModuleList(
            filter: $filter
            sort: $sort
            page: $page
            perPage: $perPage
        ) {
            items {
                _id
                title
                course_id {
                    title
                    _id
                }
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

const DELETE = gql`
    mutation ($input: [String]) {
        courseModuleDelete(input: $input) {
            total
        }
    }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Title',
            accessor: 'title',
            id: '_id',
            disableSortBy: true,
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Box>
                        <Text
                            variant='link'
                            cursor='pointer'
                            _hover={{ textDecoration: 'underline' }}
                            color='blue.500'
                            rounded='md'
                            isTruncated
                            maxWidth='350px'
                        >
                            {row.original.title}
                        </Text>
                    </Box>
                </LinkButton>
            ),
        },
        {
            Header: 'Course',
            accessor: 'course_id',
            disableSortBy: true,
            Cell: ({ row }) => (
                <LinkButton to={`courses/edit/${row.original.course_id?._id}`}>
                    <Box>
                        <Text
                            variant='link'
                            cursor='pointer'
                            _hover={{ textDecoration: 'underline' }}
                            color='blue.500'
                            rounded='md'
                            isTruncated
                            maxWidth='350px'
                        >
                            {row.original.course_id?.title}
                        </Text>
                    </Box>
                </LinkButton>
            ),
        },
    ];

    const filters = [
        // {
        //     value: 'profession',
        //     type: 'select',
        //     label: 'Profession',
        //     items: data?.listProfessions?.items.map((e) => ({
        //         label: e.title,
        //         value: e._id,
        //     })),
        //     selectValue: 'value',
        // },
        // {
        //     value: 'last_name',
        //     type: 'text',
        //     label: 'Last Name',
        //     isOperator: false,
        // },
        // {
        //    value: 'test',
        //    type: 'select',
        //    isMulti: true,
        //    isOperator: true,
        //    operators: [
        //       {
        //          label: 'Includes',
        //          value: 'in'
        //       },
        //       {
        //          label: 'Excludes',
        //          value: 'nin'
        //       }
        //    ],
        //    label: 'Operator',
        //    items: [
        //       {
        //          value: 'mattprice1@me.com',
        //          label: 'Matt Price'
        //       },
        //       {
        //          value: '665dgsgffd',
        //          label: 'This is another amazing ID'
        //       }
        //    ],
        //    selectValue: 'value'
        // }
    ];

    return (
        <Box>
            <ListWrapper
                setup={setup}
                columns={columns}
                extraMenu={extraMenu}
                gqlFetch={FETCHDATA}
                gqlDelete={DELETE}
                defaultFilter={null}
                defaultSort={{}}
                filters={filters}
                showTopPagination={false}
            />
        </Box>
    );
};

export default List;
