import React, { memo, useContext, useRef } from 'react';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Icon,
    Button,
    Image,
    Flex,
    useColorMode,
    Text,
} from '@chakra-ui/react';
import { use100vh } from 'react-div-100vh';
import { menuAtom } from '../../state/global';
import { useRecoilState, useRecoilValue } from 'recoil';
import LinkButton from '../LinkButton';
import { useRouteMatch } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import { checkPermissions, getSite, logout } from '../../helpers';
import { useOutsideClick } from '../../hooks';
import { authAtom } from '../../state/auth';
import { NucleusContext } from '../../Core';
import { MdKeyboardArrowRight } from 'react-icons/md';

const MenuLinkButton = memo(
    ({ to, children, target, leftIcon, isChild, ...rest }) => {
        const match = useRouteMatch({
            path: to,
            exact: true,
        });

        return (
            <LinkButton to={to}>
                <Button
                    variant={match ? 'solid' : 'ghost'}
                    isFullWidth
                    justifyContent='flex-start'
                    rightIcon={target && <FiArrowUpRight />}
                    fontWeight={isChild ? 'normal' : 'semibold'}
                    opacity={isChild ? 0.7 : 1}
                    leftIcon={
                        leftIcon ? (
                            <Icon
                                fontSize='20px'
                                color={match ? 'primary' : 'menuIconColour'}
                            >
                                {leftIcon}
                            </Icon>
                        ) : null
                    }
                    size='sm'
                    color={match && 'primary'}
                    mb={isChild ? '0px' : '5px'}
                    {...rest}
                >
                    {children}
                </Button>
            </LinkButton>
        );
    }
);

const MenuGroupTitle = ({ children }) => (
    <Text
        textTransform='uppercase'
        px={{ base: '10px', xl: '20px' }}
        opacity={0.5}
        mb='10px'
        fontSize='0.9em'
        textAlign='left'
        color='white'
    >
        {children}
    </Text>
);

const Menu = () => {
    const { links } = useContext(NucleusContext);
    const height = use100vh();
    const site = getSite;
    const { colorMode } = useColorMode();
    const [_menu, setMenu] = useRecoilState(menuAtom);
    const auth = useRecoilValue(authAtom);
    const menu = useRef();

    useOutsideClick(menu, () => setMenu(false));

    const hasPermission = (permissions) => {
        if (auth.currentUser) {
            if (auth.currentUser?.permissions?.some((e) => e.code === 'all')) {
                return true;
            }
            if (auth.currentUser?.permissions) {
                return checkPermissions({
                    has: auth.currentUser.permissions,
                    required: permissions,
                });
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const getTransform = _menu ? 'translateX(60px)' : 'translateX(-100%)';

    return (
        <React.Fragment>
            {auth.currentUser &&
            auth.currentUser.user?.permission_role?.length !== 0 ? (
                <>
                    <Box
                        h={height}
                        maxHeight={height}
                        w='200px'
                        bg='white'
                        borderRight='1px'
                        borderColor={{ base: 'gray.300', xl: 'transparent' }}
                        minWidth='200px'
                        py={2}
                        px={3}
                        overflowY='scroll'
                        left={0}
                        top={0}
                        zIndex={{ base: 9999, xl: 900 }}
                        transform={{
                            base: getTransform,
                            xl: 'none',
                        }}
                        transition='0.3s all ease-in-out'
                        pos={{
                            base: 'fixed',

                            xl: 'relative',
                        }}
                        ref={menu}
                    >
                        <Flex
                            px={3}
                            justify='flex-start'
                            textAlign='center'
                            my='20px'
                        >
                            <Image
                                w='auto'
                                objectFit='contain'
                                h='60px'
                                src={site?.clientLogoUrl[colorMode]}
                            />
                        </Flex>
                        <Box>
                            {links &&
                                links.map((group, groupIndex) =>
                                    group.items && group.items.length === 0
                                        ? hasPermission(
                                              group.permissions
                                                  ? group.permissions
                                                  : []
                                          ) && (
                                              <MenuLinkButton
                                                  key={`group--${groupIndex}`}
                                                  to={group.link}
                                                  target={group.target}
                                              >
                                                  {group.group}
                                              </MenuLinkButton>
                                          )
                                        : hasPermission(
                                              group.permissions
                                                  ? group.permissions
                                                  : []
                                          ) && (
                                              <Accordion
                                                  key={`${group.group}-${groupIndex}`}
                                                  collapsible='true'
                                                  multiple
                                                  borderColor='transparent'
                                                  allowToggle
                                              >
                                                  <AccordionItem>
                                                      <AccordionButton p={0}>
                                                          <MenuLinkButton
                                                              as='div'
                                                              to={group.link}
                                                              target={
                                                                  group.target
                                                              }
                                                              mb={0}
                                                              align='center'
                                                          >
                                                              <Flex
                                                                  w='100%'
                                                                  align='center'
                                                              >
                                                                  {group.group}
                                                                  <Icon
                                                                      ml='auto'
                                                                      fontSize='22px'
                                                                  >
                                                                      <MdKeyboardArrowRight />
                                                                  </Icon>
                                                              </Flex>
                                                          </MenuLinkButton>
                                                      </AccordionButton>
                                                      <AccordionPanel px={0}>
                                                          {group.items &&
                                                              group.items.map(
                                                                  (
                                                                      item,
                                                                      index
                                                                  ) =>
                                                                      hasPermission(
                                                                          item.permissions
                                                                              ? item.permissions
                                                                              : []
                                                                      ) && (
                                                                          <MenuLinkButton
                                                                              target={
                                                                                  item.target
                                                                              }
                                                                              to={
                                                                                  item.href
                                                                              }
                                                                              key={`${item.title}-${index}-${groupIndex}`}
                                                                              isChild
                                                                          >
                                                                              {
                                                                                  item.title
                                                                              }
                                                                          </MenuLinkButton>
                                                                      )
                                                              )}
                                                      </AccordionPanel>
                                                  </AccordionItem>
                                              </Accordion>
                                          )
                                )}
                        </Box>
                    </Box>
                    <Box
                        w='100vw'
                        h='100vh'
                        bg='rgba(0,0,0,0.2)'
                        position='fixed'
                        zIndex={800}
                        top={0}
                        left={0}
                        content="' '"
                        display={global.menu ? 'block' : 'none'}
                    />
                </>
            ) : null}
        </React.Fragment>
    );
};

export default Menu;
