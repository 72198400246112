import {
    Menu,
    MenuButton,
    MenuList,
    Button,
    MenuGroup,
    RadioGroup,
    Stack,
    Radio,
    Flex,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { RiArrowUpDownLine } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { tableAtom } from './atoms';

const TableSort = ({ columns }) => {
    const [tableState, setTableState] = useRecoilState(tableAtom);

    const onSortSelect = useCallback(
        (filter) => {
            setTableState((old) => ({
                ...old,
                params: {
                    ...old.params,
                    sort: filter,
                },
            }));
        },
        [setTableState]
    );

    return (
        <Menu>
            <MenuButton
                as={Button}
                leftIcon={
                    <RiArrowUpDownLine
                        fontSize='18px'
                        style={{ opacity: 0.6 }}
                    />
                }
            >
                Sort
            </MenuButton>
            <MenuList>
                <MenuGroup title='Sort By'>
                    <RadioGroup
                        defaultValue={tableState.params.sort}
                        onChange={(v) => onSortSelect(v)}
                    >
                        {columns.map(
                            (col, i) =>
                                col.accessor &&
                                !col.disableSortBy && (
                                    <Stack
                                        px='10px'
                                        pb='10px'
                                        key={`table-sort-1-${i}`}
                                    >
                                        <Radio value='_ID_ASC'>None</Radio>

                                        <Radio
                                            value={`${col.accessor.toUpperCase()}_ASC`}
                                        >
                                            {col.Header} Ascending
                                        </Radio>
                                        <Radio
                                            value={`${col.accessor.toUpperCase()}_DESC`}
                                        >
                                            {col.Header} Descending
                                        </Radio>
                                    </Stack>
                                )
                        )}
                    </RadioGroup>
                </MenuGroup>
            </MenuList>
        </Menu>
    );
};

export default TableSort;
