import React, { useMemo } from 'react';
import { AutoFields, EditView } from '../../Nucleus';
import { gql, useLazyQuery } from '@apollo/client';
import setup from './setup';

import * as yup from 'yup';
import AddLearner from './AddLearner';

const FETCH = gql`
    query FetchCourse($filter: FilterFindOneCourseInput!) {
        courseNucleusGet(filter: $filter) {
            _id
            title
            image
            slug
            description
            provider_name
            provider_email
            provider_tel
            duration
            exam_dates {
                date
                time
                slots
            }
        }
    }
`;

const EDIT = gql`
    mutation Mutation(
        $record: UpdateOneCourseInput!
        $filter: FilterUpdateOneCourseInput!
    ) {
        courseEdit(record: $record, filter: $filter) {
            record {
                _id
            }
        }
    }
`;

const CREATE = gql`
    mutation Mutation($record: CreateOneCourseInput!) {
        courseCreate(record: $record) {
            _id
        }
    }
`;

const schema = yup.object().shape({
    title: yup.string().required('Required'),
});

const EditForm = () => {
    const [fetchData, { data }] = useLazyQuery(FETCH);

    const fields = useMemo(
        () => [
            {
                group: 'Main Details',
                tab: 'Main Details',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        required: true,
                        rules: { required: true },
                    },
                    {
                        name: 'slug',
                        editOnly: true,
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        required: true,
                        rules: { required: true },
                    },
                    {
                        name: 'description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        required: true,
                        defaultValue: null,
                        rules: { required: true },
                    },
                    {
                        name: 'image',
                        component: 'file',
                        placeholder: 'Image',
                        label: 'Cover Image',
                        required: true,
                        rules: { required: true },
                    },
                    {
                        name: 'provider_name',
                        component: 'text',
                        placeholder: 'Provider Name',
                        label: 'Provider Name',
                        required: true,
                        rules: { required: true },
                    },
                    {
                        name: 'provider_email',
                        component: 'text',
                        placeholder: 'Provider Email',
                        label: 'Provider Email',
                    },
                    {
                        name: 'provider_tel',
                        component: 'text',
                        placeholder: 'Provider Telephone',
                        label: 'Provider Telephone',
                    },
                    {
                        name: 'duration',
                        component: 'number',
                        placeholder: 'Days to complete',
                        label: 'Days to complete',
                        defaultValue: 30,
                    },
                ],
            },
            {
                group: 'Exam Dates',
                tab: 'Exam Dates',
                defaultValue: [],
                fields: [
                    {
                        name: 'exam_dates',
                        component: 'repeater',
                        placeholder: 'Exam Dates',
                        label: 'Exam Dates',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'date',
                                component: 'date',
                                placeholder: 'Date',
                                label: 'Date',
                                rules: { required: true },
                                showTimeSelect: true,
                                timeFormat: 'HH:mm',
                                timeIntervals: 15,
                                timeCaption: 'Time',
                                dateFormat: 'DD/MM/YYYY - HH:mm',
                            },

                            {
                                name: 'slots',
                                component: 'number',
                                placeholder: 'Number of slots',
                                label: 'Number of slots',
                                defaultValue: 20,
                            },
                        ],
                    },
                ],
            },
        ],
        []
    );

    return (
        <EditView
            validationSchema={schema}
            fetchData={fetchData}
            data={data}
            setup={setup}
            gqlFetch={FETCH}
            gqlEdit={EDIT}
            gqlCreate={CREATE}
        >
            <AddLearner />
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
