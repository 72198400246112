import courses from './containers/Courses/routes';
import courseModules from './containers/CourseModule/routes';
import userCourses from './containers/UserCourses/routes';
import examApplications from './containers/ExamApplications/routes';

const routes = [
    ...courses,
    ...courseModules,
    ...userCourses,
    ...examApplications,
];

export default routes;
