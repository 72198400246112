import React, { useMemo } from 'react';
import LinkButton from '../../components/LinkButton';
import ListWrapper from '../../components/ListView';
import { Button } from '@chakra-ui/react';
import setup from './setup';
import { gql } from '@apollo/client';

const FETCHDATA = gql`
    query ListAdminUsers(
        $filter: FilterFindManyAdminUserInput
        $sort: SortFindManyAdminUserInput
        $page: Int
        $perPage: Int
    ) {
        listAdminUsers(
            filter: $filter
            sort: $sort
            page: $page
            perPage: $perPage
        ) {
            items {
                _id
                email
                name

                createdAt
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

const DELETE = gql`
    mutation ($input: [String]) {
        deleteAdminUsers(input: $input) {
            total
        }
    }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: () => null,
        id: 'edit',
        Cell: ({ row }) => (
            <LinkButton
                to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
                <Button
                    variant='link'
                    size='sm'
                    colorScheme='blue'
                    rounded='md'
                >
                    {row.original.name}
                </Button>
            </LinkButton>
        ),
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
];

const List = () => {
    const filters = useMemo(
        () => [
            // {
            //    name: 'from',
            //    component: 'date',
            //    placeholder: 'From'
            // },
            // {
            //    name: 'isAdmin',
            //    component: 'bool',
            //    label: 'Is Admin'
            // },
            // {
            //    name: 'tester',
            //    component: 'select',
            //    placeholder: 'Tester',
            //    options: [{ name: 'yyuss', id: 1 }],
            //    labelKey: 'name',
            //    valueKey: 'id'
            // }
        ],
        []
    );

    return (
        <ListWrapper
            filters={filters}
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            defaultFilter={null}
            defaultSort={{}}
            gqlFetch={FETCHDATA}
            gqlDelete={DELETE}
            showTopPagination={false}
        />
    );
};

export default List;
